import React, { forwardRef } from "react"
import { Formik, Form } from "formik"
import { Field } from "components/service"
import { FormBox, Stack, Input, Textarea } from "components/kit"
import * as data from "./data"

export default forwardRef(({ onCancel, onSubmit, onSubmitSuccess }, ref) => (
  <Formik
    initialValues={data.initialValues}
    validationSchema={data.validationSchema}
    onSubmit={async data => {
      await onSubmit(data)
      onSubmitSuccess()
    }}
  >
    {({ isSubmitting }) => (
      <Form>
        <FormBox
          ref={ref}
          title="Add supplier"
          confirmTitle="Send invite"
          isSubmitting={isSubmitting}
          onCancel={onCancel}
        >
          <Stack stretchItem spacing="3">
            <Stack stretchItem direction="row" spacing="3">
              <Field
                type="text"
                name="name"
                placeholder="Name"
                background="white"
                component={Input}
              />
              <Field
                type="text"
                name="email"
                placeholder="Email"
                background="white"
                component={Input}
              />
            </Stack>
            <Field
              name="message"
              placeholder="Message (optional)"
              rows="4"
              background="white"
              component={Textarea}
            />
          </Stack>
        </FormBox>
      </Form>
    )}
  </Formik>
))
