import React, { useState } from "react"
import cx from "classnames"
import { LabeledInput, CreateButton } from "components/common/order"

export default ({ topOffset, index, parameterId, onCreateOption }) => {
  const [isCreating, setCreating] = useState(false)

  return (
    <div className={cx(topOffset && "pt-3")}>
      {!isCreating ? (
        <CreateButton onClick={() => setCreating(true)}>+ Option</CreateButton>
      ) : (
        <LabeledInput
          index={index}
          autoFocus
          onSaveBlur={name => onCreateOption(parameterId, name)}
          onSaveBlurSuccess={() => setCreating(false)}
          onCancel={() => setCreating(false)}
        />
      )}
    </div>
  )
}
