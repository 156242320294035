import React from "react"

export default ({ children }) => (
  <h2
    className="text-moist font-bold"
    style={{ fontSize: 22, marginBottom: 44 }}
  >
    {children}
  </h2>
)
