import React, { createContext, useState } from "react"
import * as userService from "services/user"

export const context = createContext()

export const Provider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(
    userService.isAuthenticated()
  )
  const [data, setData] = useState()
  const [parameters, setParameters] = useState()

  return (
    <context.Provider
      value={{
        ...parameters,
        setParameters,
        data,
        setData,
        isAuthenticated,
        createSession: data => {
          userService.signIn()
          setData(data)
          setAuthenticated(true)
        },
        removeSession: () => {
          userService.signOut()
          setData(undefined)
          setAuthenticated(false)
        },
      }}
    >
      {children}
    </context.Provider>
  )
}
