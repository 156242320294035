import React, { useContext } from "react"
import cx from "classnames"
import { Stack } from "components/kit"
import { When } from "components/service"
import { OfferSelection, IndexedLabel, Updates } from "components/common/order"
import * as hoc from "./hoc"

export default hoc.provideRoot(({ optionsIds, supplierId, parameterId }) => {
  return (
    optionsIds && (
      <Stack direction="col" spacing="3" stretchItem>
        {optionsIds.map(id => (
          <Option
            key={id}
            supplierId={supplierId}
            parameterId={parameterId}
            optionId={id}
          />
        ))}
      </Stack>
    )
  )
})

const Option = hoc.provideOption(
  ({ index, name, isSelected, updates, onRemoveUpdates }) => {
    return (
      <When cond={isSelected} component={OfferSelection} top>
        <Stack spacing="4" direction="row" alignItems="center">
          <IndexedLabel theme={isSelected ? "bright" : "gray"} index={index} />
          <div className={cx("text-darkest", isSelected && "font-bold")}>
            {name}
          </div>
          {updates && !!updates.length && (
            <Updates onClick={() => onRemoveUpdates(updates)} />
          )}
        </Stack>
      </When>
    )
  }
)
