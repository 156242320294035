import { useState, useEffect } from "react"

export default (request, { limit = 5, offset = 0 } = {}) => {
  const [list, setList] = useState({ limit, offset })

  useEffect(() => {
    setList(list => ({ ...list, isLoading: true }))

    request(list.limit, list.offset).then(data =>
      setList(list => ({ ...list, data }))
    )

    setList(list => ({ ...list, isLoading: false }))
  }, [list.limit, list.offset, request])

  return {
    ...list,
    nextPage: () =>
      setList(list => ({ ...list, offset: list.offset + list.limit })),
    prevPage: () =>
      setList(list => ({ ...list, offset: list.offset - list.limit })),
    currentPage: list.offset / list.limit + 1,
    pagesCount: list.data && Math.ceil(list.data.count / list.limit),
  }
}
