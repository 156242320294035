import React, { useContext } from "react"
import { context as modalContext } from "context/modal"
import { Stack, ConfirmBox, Spinner } from "components/kit"
import { OptionFile } from "components/common/order"
import attachment from "assets/attachment.svg"
import * as hoc from "./hoc"

export default hoc.provideRoot(
  ({ filesIds, uploadingFiles, optionId }) =>
    (!!filesIds.length || !!uploadingFiles.length) && (
      <Stack spacing="2" className="ml-10 mt-3">
        {filesIds.map(id => (
          <File key={id} optionId={optionId} fileId={id} />
        ))}
        {uploadingFiles.map(file => (
          <UploadingFile key={file.id} name={file.name} />
        ))}
      </Stack>
    )
)

const UploadingFile = ({ name }) => (
  <div className="flex items-center text-darkest">
    <img src={attachment} alt="" className="w-3 mr-2" />
    {name}
    <div className="ml-2">
      <Spinner />
    </div>
  </div>
)

const File = hoc.provideFile(({ name, url, onRemove }) => {
  const modal = useContext(modalContext)

  return (
    <OptionFile
      deletable
      name={name}
      url={url}
      onRemoveClick={() =>
        modal.open(
          <ConfirmBox
            title={`Delete ${name} attachment?`}
            confirmTitle="Delete"
            cancelTitle="Cancel"
            onCancel={modal.close}
            onConfirmSuccess={modal.close}
            onConfirm={onRemove}
          />
        )
      }
    />
  )
})
