import React from "react"
import * as R from "ramda"
import styled, { keyframes, css } from "styled-components"

export default ({ color = "bright-blue" }) => (
  <Wrap className={`text-${color}`}>
    {R.times(
      i => (
        <Item color={color} key={i} n={i} />
      ),
      12
    )}
  </Wrap>
)

const animation = keyframes`
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`

const Wrap = styled.div`
  position: relative;
  height: 4em;
  width: 4em;
  font-size: 0.25rem;
`

const Item = styled.div`
  width: 0.25em;
  height: 2em;
  margin-top: -2em;
  margin-left: -0.125em;
  position: absolute;
  left: 50%;
  top: 50%;
  border-top: 1em solid;
  transform-origin: 50% 100%;
  ${({ n }) => css`
    transform: rotate(${n * 30}deg);
    animation: ${animation} 0.6s calc((${n} - 12) * 0.05s) infinite;
  `}
`
