import React, { useState, useEffect } from "react"
import * as orderApi from "api/order"
import { PageLoader } from "components/kit"
import * as conversationsOnlineContext from "context/conversations-online"
import Buyer from "./Buyer"
import Supplier from "./Supplier"

export default ({ orderId }) => {
  const [order, setOrder] = useState()

  useEffect(() => {
    orderApi.fetchEntry(orderId).then(setOrder)

    if (process.env.NODE_ENV === "production") {
      setInterval(() => {
        orderApi.fetchEntry(orderId).then(setOrder)
      }, 10000)
    }
  }, [orderId])

  if (!order) {
    return <PageLoader />
  }

  return (
    <conversationsOnlineContext.Provider>
      {order.role === "buyer" ? (
        <Buyer order={order} />
      ) : (
        <Supplier order={order} />
      )}
    </conversationsOnlineContext.Provider>
  )
}
