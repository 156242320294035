import React from "react"
import cx from "classnames"
import { SmartInput, ContextMenu } from "components/kit"
import { When } from "components/service"

export default ({
  title,
  postfix,
  leftLink,
  border,
  isEditable,
  autoFocus,
  contextMenu,
  onCancel,
  onSaveBlur,
  onSaveBlurSuccess,
}) => (
  <When
    cond={contextMenu}
    component={ContextMenu}
    items={contextMenu}
    offset="6"
  >
    <div
      className={cx(
        "h-12 px-6 bg-atomic text-darkest flex items-center flex-shrink-0 border-b border-paper",
        "border-l border-paper"
      )}
    >
      {leftLink ||
        ((title || isEditable) && (
          <SmartInput
            xSpace="2"
            ySpace="1"
            textColor="moist"
            hoverBg="tune"
            focusBg="tune"
            fontWeight="bold"
            postfix={postfix}
            defaultValue={title}
            isEditable={isEditable}
            autoFocus={autoFocus}
            onCancel={onCancel}
            onSaveBlur={onSaveBlur}
            onSaveBlurSuccess={onSaveBlurSuccess}
          />
        ))}
    </div>
  </When>
)
