import { request } from "utils/index"

export const createEntry = (optionId, { status }) =>
  request({
    url: `/options/${optionId}/responses`,
    method: "POST",
    data: {
      status,
    },
  })

export const updateEntry = (responseId, { status, comment }) =>
  request({
    url: `/responses/${responseId}`,
    method: "PATCH",
    data: {
      status,
      comment,
    },
  })
