import React, { useRef } from "react"

export default ({ children, multiple, onSelect }) => {
  const ref = useRef()

  const open = () => {
    ref.current.click()
  }

  return (
    <>
      <div className="hidden">
        <input
          ref={ref}
          onChange={e => onSelect(e.target.files)}
          onClick={e => (e.target.value = null)}
          type="file"
          multiple={multiple}
        />
      </div>
      {children(open)}
    </>
  )
}
