export const PARAMETER_CONVERSATION_CREATED = "PARAMETER_CONVERSATION_CREATED"
export const PARAMETER_SUGGESTED = "PARAMETER_SUGGESTED"
export const PARAMETER_REMOVED = "PARAMETER_REMOVED"
export const PARAMETER_RENAMED = "PARAMETER_RENAMED"

export const OPTION_SUGGESTED = "OPTION_SUGGESTED"
export const OPTION_REMOVED = "OPTION_REMOVED"
export const OPTION_RENAMED = "OPTION_RENAMED"

export const OPEN_CREATION = "OPEN_CREATION"
export const OPEN_EDITION = "OPEN_EDITION"
export const CLOSE_CURRENT_OFFER = "CLOSE_CURRENT_OFFER"
export const SELECT_RESPONSE = "SELECT_RESPONSE"

export const CREATE_OFFER_REQUEST = "CREATE_OFFER_REQUEST"
export const CREATE_OFFER_SUCCESS = "CREATE_OFFER_SUCCESS"

export const UPDATE_OFFER_REQUEST = "UPDATE_OFFER_REQUEST"
export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_SUCCESS"

export const REMOVE_OFFER_SUCCESS = "REMOVE_OFFER_SUCCESS"

export const RESPOND_SUCCESS = "RESPOND_SUCCESS"

export const LOAD_ORDER = "LOAD_ORDER"

export const NEW_UNREAD_MESSAGE = "NEW_UNREAD_MESSAGE"

export const UPDATE_REMOVED = "UPDATE_REMOVED"
