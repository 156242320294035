import React from "react"
import cx from "classnames"

export default ({ children }) => (
  <div
    className={cx(
      "font-bold w-px whitespace-no-wrap",
      children ? "text-moist" : "text-gray-400"
    )}
  >
    {children || "Untitled"}
  </div>
)
