import React from "react"
import cx from "classnames"
import chevL from "assets/chev-l.svg"
import chevR from "assets/chev-r.svg"

export default ({
  items,
  fields,
  pagesCount,
  currentPage,
  prevPage,
  nextPage,
  renderRow,
  onRowClick,
  hasTopLine,
}) => {
  const thClassName =
    "text-left uppercase font-rubik font-medium text-xs text-centaur pb-5"
  const tdClassName =
    "bg-white h-20 text-darkest border-t border-b border-paper"
  const firstCell = "pl-12 pr-12"
  const bottomBorder = "border-b-2 border-light-blue"

  return (
    <div className={cx(hasTopLine && "pt-5 border-t border-paper")}>
      <table className="w-full">
        <thead>
          <tr>
            {fields.map((field, i) => (
              <th key={i} className={cx(thClassName, i === 0 && firstCell)}>
                {field}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item, itemI) => {
            const cells = renderRow(item)

            return (
              <tr
                onClick={() => onRowClick(item)}
                key={itemI}
                className={onRowClick && "cursor-pointer"}
              >
                {cells.map((cell, cellI) => (
                  <td
                    key={cellI}
                    className={cx(
                      tdClassName,
                      cellI === 0 && firstCell + " border-l",
                      cellI === cells.length - 1 && "border-r",
                      itemI !== items.length - 1 && bottomBorder
                    )}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
      {pagesCount > 1 && (
        <Pagination
          pagesCount={pagesCount}
          currentPage={currentPage}
          prevPage={prevPage}
          nextPage={nextPage}
        />
      )}
    </div>
  )
}

const Pagination = ({ pagesCount, currentPage, prevPage, nextPage }) => {
  const prevDisabled = currentPage === 1
  const nextDisabled = currentPage === pagesCount

  return (
    <div className="inline-flex items-center bg-white rounded border border-paper h-12 px-5 font-rubik text-xs uppercase mt-3 text-moist font-medium">
      <button
        disabled={prevDisabled}
        onClick={prevPage}
        className={cx("cursor-pointer", prevDisabled && "opacity-50")}
      >
        <img src={chevL} alt="" />
      </button>
      <span className="px-6">
        {currentPage}/{pagesCount} pages
      </span>
      <button
        disabled={nextDisabled}
        onClick={nextPage}
        className={cx("cursor-pointer", nextDisabled && "opacity-50")}
      >
        <img src={chevR} alt="" />
      </button>
    </div>
  )
}
