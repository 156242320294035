import React from "react"
import { SmartInput } from "components/kit"
import HeaderTitle from "../HeaderTitle"

export default ({ name, isEditable, onSaveBlur }) => {
  return (
    <div className="flex flex-col">
      <div className="mt-3">
        <SmartInput
          xSpace="4"
          ySpace="2"
          fontWeight="semibold"
          fontSize="2xl"
          textColor="moist"
          hoverBg="creed"
          focusBg="bower"
          placeholder="Untitled"
          defaultValue={name}
          isEditable={isEditable}
          onSaveBlur={onSaveBlur}
        />
      </div>
    </div>
  )
}
