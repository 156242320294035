import * as yup from "yup"

export const validationSchema = yup.object().shape({
  name: yup.string().required("Please enter name"),
  email: yup
    .string()
    .email("Wrong email format")
    .required("Please enter email"),
  message: yup.string(),
})

export const initialValues = {
  name: "",
  email: "",
  message: "",
}
