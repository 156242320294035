import React from "react"
import { Link } from "@reach/router"

export default ({ children, to }) => (
  <div className="text-center mt-12">
    <Link to={to} className="text-mild-blue font-bold">
      {children}
    </Link>
  </div>
)
