import { useEffect, useReducer, useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import shortid from "shortid"
import * as conversationApi from "api/conversation"
import * as fileApi from "api/file"

export const useMessages = (conversationId, createConversation) => {
  const reduxDispatch = useDispatch()
  const [state, dispatch] = useReducer(
    messagesReducer,
    !conversationId ? { items: [], count: 0, offset: 0 } : { conversationId }
  )
  const limit = 10

  const fetchMessages = useCallback(
    offset => {
      dispatch({ type: "load_request" })
      return conversationApi
        .fetchMessages(state.conversationId, limit, offset)
        .then(data =>
          dispatch({ type: "load_success", payload: { ...data, offset } })
        )
    },
    [state.conversationId]
  )

  const send = useCallback(
    async (text, attachmentIds, replyTo, refOptionId, refOfferId) => {
      const conversationId =
        state.conversationId || (await createConversation())

      const messages = await conversationApi.sendMessage(
        conversationId,
        text,
        attachmentIds,
        replyTo,
        refOptionId,
        refOfferId
      )
      dispatch({ type: "send_success", payload: messages })
    },
    [state.conversationId, createConversation]
  )

  const lazyLoad = useCallback(async () => {
    const offset = limit + state.offset
    if (!state.isLoading && offset < state.count) {
      return await fetchMessages(offset)
    }
  }, [fetchMessages, state.isLoading, state.offset, state.count])

  useEffect(() => {
    if (state.conversationId && !state.items) {
      fetchMessages(0)
    }
  }, [fetchMessages, state.conversationId, state.items])

  return {
    ...state,
    send,
    lazyLoad,
    read: async (userId, items, offset) => {
      const unread = items.reduce(
        (acc, message, i) =>
          message.read ||
          message.sender.id === userId ||
          i >= items.length - offset
            ? acc
            : [...acc, message.id],
        []
      )
      if (unread.length) {
        await conversationApi.readMessages(unread)
        dispatch({
          type: "read_messages",
          payload: {
            items: unread,
          },
        })
        reduxDispatch({
          type: "READ_MESSAGES",
          payload: {
            conversationId: state.conversationId,
            count: unread.length,
          },
        })
      }
    },
    remove: async id => {
      await conversationApi.removeMessage(id)
      dispatch({ type: "remove_success", payload: { id } })
    },
    append: message => {
      dispatch({ type: "append", payload: { message } })
    },
  }
}

const messagesReducer = (state, action = {}) => {
  switch (action.type) {
    case "create_conversation_success":
      return {
        ...state,
        conversationId: action.payload.conversationId,
      }
    case "load_request":
      return {
        ...state,
        isLoading: true,
      }
    case "load_success":
      return {
        ...state,
        offset: action.payload.offset,
        count: action.payload.count,
        items: [...action.payload.items, ...(state.items || [])],
        isLoading: false,
      }
    case "send_success":
      return {
        ...state,
        items: [...state.items, ...action.payload],
      }
    case "remove_success":
      return {
        ...state,
        items: state.items.map(item =>
          item.id !== action.payload.id ? item : { ...item, is_deleted: true }
        ),
      }
    case "append":
      return {
        ...state,
        items: [...state.items, action.payload.message],
      }
    case "read_messages":
      return {
        ...state,
        items: state.items.map(item =>
          !action.payload.items.includes(item.id)
            ? item
            : { ...item, read: true }
        ),
      }
    default:
      return state
  }
}

export const useAttachments = () => {
  const [items, setItems] = useState([])

  return {
    items,
    upload: async files => {
      await Promise.all(
        [...files].map(async file => {
          const uploadId = shortid.generate()
          setItems(items => [...items, { uploadId, name: file.name }])
          const uploaded = await fileApi.upload(file, "chat_attachment")
          setItems(items =>
            items.map(item =>
              item.uploadId !== uploadId
                ? item
                : { id: uploaded.id, name: uploaded.original_name }
            )
          )
        })
      )
    },
    remove: async fileId => {
      setItems(items => items.filter(item => item.id !== fileId))
      await fileApi.remove(fileId)
    },
    clear: () => setItems([]),
  }
}
