import { request } from "utils/index"

export const fetchBuyingList = (limit, offset) =>
  request({
    url: `/me/orders/buying?limit=${limit}&offset=${offset}`,
    method: "GET",
  })

export const fetchSupplyingList = (limit, offset) =>
  request({
    url: `/me/orders/supplying?limit=${limit}&offset=${offset}`,
    method: "GET",
  })

export const createEntry = () =>
  request({
    url: "/orders",
    method: "POST",
  })

export const fetchEntry = orderId =>
  request({
    url: `/orders/${orderId}`,
    method: "GET",
  })

export const updateEntry = (orderId, name) =>
  request({
    url: `/orders/${orderId}`,
    method: "PATCH",
    data: {
      name,
    },
  })

export const removeSupplier = (orderId, supplierId) =>
  request({
    url: `/orders/${orderId}/suppliers/${supplierId}`,
    method: "DELETE",
  })

export const accept = orderId =>
  request({
    url: `/orders/${orderId}/suppliers/me`,
    method: "PUT",
  })

export const createConversation = (orderId, userId) =>
  request({
    url: `/orders/${orderId}/users/${userId}/conversation`,
    method: "PUT",
  })
