import React from "react"
import * as utils from "utils/index"

// front-end compression?
export default ({ name, value, onChange }) => {
  return (
    <label className="flex flex-col items-center cursor-pointer">
      <div className="w-24 h-24 bg-atomic rounded-full border-2 border-paper">
        {value && (
          <img className="rounded-full w-full h-full" alt="" src={value} />
        )}
        <input
          name={name}
          type="file"
          className="absolute invisible"
          onChange={async e => {
            const file = e.target.files[0]

            if (!file) return

            const base64 = await utils.fileToBase64(file)

            onChange({
              target: {
                name,
                value: base64,
              },
            })
          }}
        />
      </div>
      <span className="mt-6 text-mild-blue text-center">Add photo</span>
    </label>
  )
}
