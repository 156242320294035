import React, { createContext, useRef } from "react"
import connectWs from "./ws"

export const context = createContext()

export const Provider = ({ children }) => {
  const connections = useRef({})
  const subscriptions = useRef([])

  const connect = conversationId => {
    connections.current[conversationId] = connectWs(conversationId, event =>
      subscriptions.current.forEach(
        item => item.conversationId === conversationId && item.callback(event)
      )
    )
    return () => {
      connections.current[conversationId]()
    }
  }

  const subscribe = (conversationId, callback) => {
    subscriptions.current.push({ conversationId, callback })
    return () =>
      (subscriptions.current = subscriptions.current.filter(
        item => item.callback !== callback
      ))
  }

  return (
    <context.Provider
      value={{
        subscribe,
        connect,
      }}
    >
      {children}
    </context.Provider>
  )
}
