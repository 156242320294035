import React from "react"
import { createStore } from "redux"
import { Provider } from "react-redux"
import { normalize } from "normalizr"
import reducer from "./reducers"
import * as schemas from "./schemas"

export default ({ order, children }) => {
  const { entities, result } = normalize(order, {
    suppliers: [schemas.supplierSchema],
    parameters: [schemas.parameterSchema],
    invitations: [schemas.invitationSchema],
  })
  const initialState = { entities, entry: result }

  const store = createStore(
    reducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )

  return <Provider store={store}>{children}</Provider>
}
