import React from "react"
import cx from "classnames"

const height = "9999px"

export default ({ children, color = "green", zIndex, top, bottom }) => (
  <div className="relative" style={{ zIndex }}>
    <>
      <div
        className={cx(
          "absolute h-px w-3 left-0 top-0 bottom-0 -mx-3 my-auto",
          `bg-${color}`
        )}
      />
      {top && (
        <div
          style={{ height }}
          className={cx(
            "absolute w-px left-0 bottom-0 mb-3 -mx-3",
            `bg-${color}`
          )}
        />
      )}
      {bottom && (
        <div
          style={{ height }}
          className={cx("absolute w-px left-0 top-0 mt-3 -mx-3", `bg-${color}`)}
        />
      )}
    </>
    {children}
  </div>
)
