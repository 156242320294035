import React from "react"
import cx from "classnames"
import { Stack } from "components/kit"
import { When } from "components/service"
import { OfferSelection, Column, Cell, Label } from "components/common/order"
import * as hoc from "./hoc"

export default hoc.provideRoot(
  ({ hasOffers, suppliersIds }) =>
    hasOffers && (
      <div className="flex overflow-hidden">
        <Column>
          <Cell border title="Offers" />
        </Column>
        {suppliersIds.map((id, i) => (
          <Offers
            border={i !== suppliersIds.length - 1}
            key={id}
            supplierId={id}
          />
        ))}
      </div>
    )
)

const Offers = hoc.provideOffers(({ border, offersIds, supplierId }) => (
  <Column>
    <Cell border={border}>
      <Stack stretchItem direction="col" spacing="3">
        {offersIds.map(id => (
          <Offer key={id} supplierId={supplierId} offerId={id} />
        ))}
      </Stack>
    </Cell>
  </Column>
))

const Offer = hoc.provideOffer(
  ({ supplierId, offerId, price, isSelected, onSelect }) => {
    return (
      <When cond={isSelected} component={OfferSelection} bottom>
        <Stack
          alignItems="center"
          direction="row"
          spacing="4"
          className="cursor-pointer"
          onClick={onSelect}
        >
          <Label theme={!isSelected ? "gray" : "bright"}>Offer A</Label>
          <span className={cx("text-darkest", isSelected && "font-bold")}>
            ${price}
          </span>
        </Stack>
      </When>
    )
  }
)
