import { connect } from "hoc/index"
import * as orderSelectors from "modules/supplier/selectors/order"
import * as actions from "modules/supplier/actions"

export const provideRoot = connect(
  {
    orderId: orderSelectors.getId,
  },
  dispatch => ({
    onOrderLoaded: order => dispatch(actions.loadOrder(order)),
  })
)
