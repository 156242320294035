import React from "react"
import Spinner from "../Spinner"

export default () => {
  return (
    <div className="bg-lightest h-screen flex items-center justify-center">
      <Spinner />
    </div>
  )
}
