import React from "react"
import { Stack } from "components/kit"
import { OptionFile } from "components/common/order"
import * as hoc from "./hoc"

export default hoc.provideRoot(
  ({ filesIds }) =>
    !!filesIds.length && (
      <Stack spacing="2" className="ml-10 mt-3">
        {filesIds.map(id => (
          <File key={id} fileId={id} />
        ))}
      </Stack>
    )
)

const File = hoc.provideFile(OptionFile)
