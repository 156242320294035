import React, { useState } from "react"
import moment from "moment"
import * as R from "ramda"
import { navigate } from "@reach/router"
import { Layout } from "components/common/app"
import { Button, Tabs } from "components/kit"
import * as orderApi from "api/order"
import * as invitationApi from "api/invitation"
import * as orderConstants from "constants/order"
import { useList } from "hooks/index"
import { replaceParams } from "utils/index"
import * as paths from "paths.js"
import projectsPlaceholder from "assets/projects-placeholder.svg"
import Subtitle from "./Subtitle"
import Table from "./Table"
import OrderName from "./OrderName"
import Tag from "./Tag"

export default () => {
  const [isCreating, setCreating] = useState(false)
  const buying = useList(orderApi.fetchBuyingList)
  const supplying = useList(orderApi.fetchSupplyingList)
  const invitations = useList(invitationApi.fetchList)

  if (!buying.data || !supplying.data || !invitations.data) {
    return <div />
  }

  const hasBuying = !!buying.data.count
  const hasSupplying = !!supplying.data.count
  const hasInvitations = !!invitations.data.count

  const goToOrder = id => navigate(replaceParams(paths.order, { orderId: id }))
  const createOrder = async () => {
    setCreating(true)
    const order = await orderApi.createEntry()
    setCreating(false)
    goToOrder(order.id)
  }

  return (
    <Layout>
      <div className="flex items-center bg-atomic px-16 py-12 mb-20 border-b border-paper">
        <h1 style={{ fontSize: 22 }} className="text-moist font-bold">
          Projects
        </h1>
        <div className="ml-auto">
          <Button onClick={createOrder} isSpinning={isCreating}>
            Start new
          </Button>
        </div>
      </div>
      <div className="px-16 pb-12">
        {!hasBuying && !hasSupplying && (
          <div className="h-full flex flex-col items-center justify-center">
            <img className="mb-6" src={projectsPlaceholder} alt="" />
            <Button onClick={createOrder} isSpinning={isCreating}>
              Start first project
            </Button>
          </div>
        )}
        {hasBuying && (
          <>
            <Subtitle>Buying</Subtitle>
            <Table
              {...buying}
              hasTopLine
              fields={["Name", "Started", "Status"]}
              items={buying.data.items}
              renderRow={item => [
                <OrderName>{item.name}</OrderName>,
                <div className="font-bold">
                  {moment(item.created_at).fromNow()}
                </div>,
                <Tag>{orderConstants.statuses[item.status]}</Tag>,
              ]}
              onRowClick={item => goToOrder(item.id)}
            />
          </>
        )}
        {(hasSupplying || hasInvitations) && (
          <div className="mt-24">
            <Subtitle>Selling</Subtitle>
            <Tabs
              items={[
                hasSupplying && {
                  title: `Current projects (${supplying.data.count})`,
                  body: (
                    <Table
                      {...supplying}
                      items={supplying.data.items}
                      fields={["Name", "Started", "Status"]}
                      renderRow={item => [
                        <OrderName>{item.name}</OrderName>,
                        moment(item.created_at).fromNow(),
                        <Tag>{orderConstants.statuses[item.status]}</Tag>,
                      ]}
                      onRowClick={item => goToOrder(item.id)}
                    />
                  ),
                },
                hasInvitations && {
                  title: `Invites (${invitations.data.count})`,
                  body: (
                    <Table
                      {...invitations}
                      items={invitations.data.items}
                      fields={["Name", "Buyer", "Company", "Invited"]}
                      renderRow={item => [
                        <OrderName>{item.order_name}</OrderName>,
                        item.buyer_name,
                        item.buyer_company,
                        moment(item.created_at).fromNow(),
                      ]}
                      onRowClick={item => goToOrder(item.order_id)}
                    />
                  ),
                },
              ].filter(R.identity)}
            />
          </div>
        )}
      </div>
    </Layout>
  )
}
