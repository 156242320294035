import { request, createFormData } from "utils/index"

export const updateEntry = (optionId, name) =>
  request({
    url: `/options/${optionId}`,
    method: "PATCH",
    data: {
      name,
    },
  })

export const createEntry = (parameterId, name) =>
  request({
    url: `/parameters/${parameterId}/options`,
    method: "POST",
    data: {
      name,
    },
  })

export const removeEntry = optionId =>
  request({
    url: `/options/${optionId}`,
    method: "DELETE",
  })

export const uploadFile = (optionId, file) =>
  request({
    url: `/options/${optionId}/files`,
    method: "POST",
    data: createFormData({ file }),
  })

export const removeFile = (optionId, fileId) =>
  request({
    url: `/options/${optionId}/files/${fileId}`,
    method: "DELETE",
  })

export const suggest = (parameterId, name) =>
  request({
    url: `/parameters/${parameterId}/options/suggested`,
    method: "POST",
    data: {
      name,
    },
  })

export const acceptSuggested = optionId =>
  request({
    url: `/options/${optionId}/accepted`,
    method: "POST",
  })
