import React from "react"
import logo from "assets/logo.svg"
import shapes from "assets/shapes.svg"

export default ({ children, title }) => {
  return (
    <div className="flex items-center justify-center bg-cold min-h-screen relative overflow-hidden">
      <div className="flex flex-1 flex-col items-center m-4 relative">
        {title && (
          <p className="text-moist text-2xl mb-10 text-center font-bold max-w-sm w-full px-8">
            {title}
          </p>
        )}
        {children}
      </div>
    </div>
  )
}
