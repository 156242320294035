import React from "react"
import * as notifications from "./notifications"
import * as user from "./user"
import * as modal from "./modal"

export default ({ children }) => (
  <user.Provider>
    <notifications.Provider>
      <modal.Provider>{children}</modal.Provider>
    </notifications.Provider>
  </user.Provider>
)
