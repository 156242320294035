import { connect } from "hoc/index"
import * as selectors from "modules/buyer/selectors"
import * as actions from "modules/buyer/actions"
import * as updateApi from "api/update"

export const provideRoot = connect({
  optionsIds: selectors.getOptionsIds,
})

export const provideOption = connect(
  {
    index: selectors.getOptionIndex,
    name: selectors.getOptionName,
    isSelected: selectors.isResponseSelected,
    updates: selectors.getOptionUpdates,
  },
  dispatch => ({
    onRemoveUpdates: async updates => {
      for (let update of updates) {
        dispatch(actions.updateRemoved(update.id))
      }
      await Promise.all(updates.map(update => updateApi.remove(update.id)))
    },
  })
)
