import * as R from "ramda"
import { connect } from "hoc/index"
import * as offerApi from "api/offer"
import * as orderSelectors from "modules/supplier/selectors/order"
import * as offerSelectors from "modules/supplier/selectors/offer"
import * as actions from "modules/supplier/actions"

export const provideRoot = connect(
  {
    offersIds: offerSelectors.getIds,
    isCreationActive: offerSelectors.isCreationActive,
    role: orderSelectors.getRole,
  },
  (dispatch, { offerId }) => ({
    onOpenCreation: () => dispatch(actions.openCreation()),
    onClose: () => dispatch(actions.closeCurrentOffer()),
  })
)

export const provideOfferItem = connect(
  {
    price: offerSelectors.getPrice,
    isSelected: offerSelectors.isEditing,
    isOfferCompleted: offerSelectors.isCompleted,
  },
  (dispatch, { offerId }) => ({
    onClick: () => dispatch(actions.openEdition(offerId)),
    onSubmit: price => dispatch(actions.requestOfferUpdate(offerId, +price)),
    onRemove: async offerId => {
      await offerApi.remove(offerId)
      dispatch(actions.successOfferRemove(offerId))
      dispatch(actions.closeCurrentOffer())
    },
  })
)

export const provideOfferCreation = R.compose(
  connect(
    {
      isOfferCompleted: offerSelectors.isCompleted,
    },
    dispatch => ({
      onSubmit: price => dispatch(actions.requestOfferCreation(+price)),
    })
  )
)
