import React, { useRef } from "react"
import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import { Provider } from "react-redux"
import normalize from "./schemas"
import reducer from "./reducers"
import saga from "./sagas"
import * as actions from "./actions"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default ({ order, children }) => {
  const initialState = normalize(order)
  const sagaMiddleware = createSagaMiddleware()
  const store = useRef()

  if (!store.current) {
    store.current = createStore(
      reducer,
      initialState,
      composeEnhancers(applyMiddleware(sagaMiddleware))
    )

    sagaMiddleware.run(saga)
  } else {
    store.current.dispatch(actions.loadOrder(initialState, true))
  }

  return <Provider store={store.current}>{children}</Provider>
}
