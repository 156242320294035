import * as yup from "yup"

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Wrong email format")
    .required("Please enter your email"),
  password: yup
    .string()
    .min(6, "6 or more symbols required")
    .required("Please enter your password"),
})

export const initialValues = {
  email: "",
  password: "",
}
