import { createSelector } from "reselect"

export const getId = (state, { optionId }) =>
  state.entities.options[optionId].response

export const getStatus = createSelector(
  [state => state.entities.responses, getId],
  (responsesEntities, responseId) =>
    responseId && responsesEntities[responseId].status
)
