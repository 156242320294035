import { request } from "utils/index"

export const updateEntry = (parameterId, name) =>
  request({
    url: `/parameters/${parameterId}`,
    method: "PATCH",
    data: {
      name,
    },
  })

export const createEntry = (orderId, name) =>
  request({
    url: `/orders/${orderId}/parameters`,
    method: "POST",
    data: {
      name,
    },
  })

export const removeEntry = parameterId =>
  request({
    url: `/parameters/${parameterId}`,
    method: "DELETE",
  })

export const createConversation = (parameterId, userId) =>
  request({
    url: `/parameters/${parameterId}/users/${userId}/conversation`,
    method: "PUT",
  })

export const suggest = (orderId, name) =>
  request({
    url: `/orders/${orderId}/parameters/suggested`,
    method: "POST",
    data: {
      name,
    },
  })

export const acceptSuggested = (parameterId, name) =>
  request({
    url: `/parameters/${parameterId}/accepted`,
    method: "POST",
  })
