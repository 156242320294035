import React, { useContext } from "react"
import { Redirect } from "@reach/router"
import { context as userContext } from "context/user"
import Authenticated from "./Authenticated"
import * as utils from "./utils"

export default ({ component: Component, guard, loader, ...rest }) => {
  const user = useContext(userContext)
  const content = <Component {...rest} />

  if (!guard || (!user.isAuthenticated && guard(false))) {
    return content
  }

  if (!user.isAuthenticated && !guard(false)) {
    return (
      <Redirect
        noThrow
        to={user.forceRedirect || utils.redirectTo(false, void 0, user)}
      />
    )
  }

  return (
    <Authenticated guard={guard} user={user} loader={loader}>
      {content}
    </Authenticated>
  )
}
