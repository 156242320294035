import { request, createFormData } from "utils/index"

export const upload = (file, type) =>
  request({
    url: `/files?type=${type}`,
    method: "POST",
    data: createFormData({ file }),
  })

export const remove = fileId =>
  request({
    url: `/files/${fileId}`,
    method: "DELETE",
  })
