import React, { useState, useRef } from "react"
import ReactDOM from "react-dom"
import cx from "classnames"
import { useClickOutside } from "hooks/index"
import Menu from "../Menu"
import dots from "assets/dots.svg"

export default ({
  children,
  items,
  offset,
  size = "regular",
  offsetType = "positive",
  verticalAlign = "center",
}) => {
  const [isVisible, setVisible] = useState(false)
  const ref = useRef()
  const positionRef = useRef()
  const menuRef = useRef()
  const sizeValue = sizes[size]

  const rightSpace =
    ref.current &&
    document.body.offsetWidth - ref.current.getBoundingClientRect().left
  const align = rightSpace < 250 ? "right" : "left"

  useClickOutside(menuRef, () => setVisible(false))

  const pos = positionRef.current && getOffset(positionRef.current)

  return (
    <div className="relative group">
      {children}
      <div
        ref={ref}
        style={{
          transform:
            align === "left" ? "translateX(calc(100% - 2rem))" : "none",
        }}
        className={cx(
          "absolute top-0 right-0 flex h-full z-10",
          `items-${verticalAlign}`,
          offset && offsets[verticalAlign](offset, offsetType)
        )}
      >
        <div
          className={cx(
            `h-${sizeValue} flex flex-col`,
            align === "right" && "items-end"
          )}
        >
          <div
            onClick={() => setVisible(!isVisible)}
            className={cx(
              "bg-tune group-hover:visible rounded flex flex-shrink-0 items-center justify-center cursor-pointer",
              `w-${sizeValue} h-${sizeValue}`,
              isVisible ? "visible" : "invisible"
            )}
          >
            <img className={`w-${sizeValue / 2}`} src={dots} alt="" />
          </div>
          <div className="mt-2">
            <div ref={positionRef} />
            {isVisible && (
              <MenuPortal>
                <div
                  ref={menuRef}
                  style={{
                    position: "absolute",
                    zIndex: "9999999",
                    transform: align === "right" && "translateX(-100%)",
                    ...pos,
                  }}
                >
                  <Menu items={items} onClick={() => setVisible(false)} />
                </div>
              </MenuPortal>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const MenuPortal = ({ children }) =>
  ReactDOM.createPortal(children, document.getElementById("context-menu"))

const offsets = {
  start: (offset, type) =>
    type === "negative"
      ? `-mt-${offset} -mr-${offset}`
      : `mt-${offset} mr-${offset}`,
  center: (offset, type) =>
    type === "negative" ? `-mr-${offset}` : `mr-${offset}`,
  end: (offset, type) =>
    type === "negative"
      ? `mt-${offset} -mr-${offset}`
      : `mb-${offset} mr-${offset}`,
}

const sizes = {
  small: 6,
  regular: 8,
}

function getOffset(el) {
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop

  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
  }
}
