import { schema, normalize } from "normalizr"

export const offerSchema = new schema.Entity("offers")
export const conversationSchema = new schema.Entity("conversations")
export const fileSchema = new schema.Entity("files")
export const responseSchema = new schema.Entity("responses")
export const optionSchema = new schema.Entity("options", {
  response: responseSchema,
  files: [fileSchema],
})
export const parameterSchema = new schema.Entity("parameters", {
  options: [optionSchema],
  conversation: conversationSchema,
})

export default order => {
  const { result: entry, entities } = normalize(order, {
    parameters: [parameterSchema],
    offers: [offerSchema],
    conversation: conversationSchema,
  })

  return { entry, entities }
}
