import * as constants from "./constants"

const entry = (state = {}, action) => {
  switch (action.type) {
    case constants.CREATE_OFFER_SUCCESS:
      return {
        ...state,
        offers: state.offers
          ? [...state.offers, action.payload.offer.id]
          : [action.payload.offer.id],
      }
    case constants.REMOVE_OFFER_SUCCESS:
      return {
        ...state,
        offers: state.offers.filter(id => id !== action.payload.offerId),
      }
    case constants.PARAMETER_SUGGESTED:
      return {
        ...state,
        parameters: [...(state.parameters || []), action.payload.parameter.id],
      }
    case constants.PARAMETER_REMOVED:
      return {
        ...state,
        parameters: (state.parameters || []).filter(
          id => id !== action.payload.parameterId
        ),
      }
    case constants.UPDATE_REMOVED:
      return {
        ...state,
        updates: state.updates.filter(
          update => update.id !== action.payload.updateId
        ),
      }
    default:
      return state
  }
}

const offers = (state = {}, action) => {
  switch (action.type) {
    case constants.CREATE_OFFER_SUCCESS:
    case constants.UPDATE_OFFER_SUCCESS:
      return {
        ...state,
        [action.payload.offer.id]: action.payload.offer,
      }
    default:
      return state
  }
}

const responses = (state = {}, action) => {
  switch (action.type) {
    case constants.RESPOND_SUCCESS:
      return {
        ...state,
        [action.payload.response.id]: action.payload.response,
      }
    default:
      return state
  }
}

const options = (state = {}, action) => {
  switch (action.type) {
    case constants.RESPOND_SUCCESS:
      return {
        ...state,
        [action.payload.optionId]: {
          ...state[action.payload.optionId],
          response: action.payload.response.id,
        },
      }
    case constants.OPTION_SUGGESTED:
      return {
        ...state,
        [action.payload.option.id]: action.payload.option,
      }

    case constants.OPTION_RENAMED:
      return {
        ...state,
        [action.payload.optionId]: option(
          state[action.payload.optionId],
          action
        ),
      }
    default:
      return state
  }
}

const option = (state, action) => {
  switch (action.type) {
    case constants.OPTION_RENAMED:
      return {
        ...state,
        name: action.payload.name,
      }
    default:
      return state
  }
}

const currentOffer = (state = { is_visible: false }, action) => {
  switch (action.type) {
    case constants.OPEN_CREATION:
      return {
        is_visible: true,
        type: "creation",
      }
    case constants.OPEN_EDITION:
      return {
        is_visible: true,
        type: "editing",
        offer_id: action.payload.offerId,
      }
    case constants.SELECT_RESPONSE:
      return {
        ...state,
        responses: {
          ...state.responses,
          [action.payload.parameterId]: action.payload.responseId,
        },
      }
    case constants.CLOSE_CURRENT_OFFER:
      return { is_visible: false }
    default:
      return state
  }
}

export const conversations = (state = {}, action) => {
  switch (action.type) {
    case "READ_MESSAGES":
    case constants.NEW_UNREAD_MESSAGE:
    case constants.PARAMETER_CONVERSATION_CREATED:
      return {
        ...state,
        [action.payload.conversationId]: conversation(
          state[action.payload.conversationId],
          action
        ),
      }
    default:
      return state
  }
}

export const conversation = (state, action) => {
  switch (action.type) {
    case "READ_MESSAGES":
      return {
        ...state,
        unread_messages: state.unread_messages - action.payload.count,
      }
    case constants.PARAMETER_CONVERSATION_CREATED:
      return {
        ...state,
        [action.payload.conversationId]: {
          id: action.payload.conversationId,
        },
      }
    case constants.NEW_UNREAD_MESSAGE:
      return {
        ...state,
        unread_messages: (state.unread_messages || 0) + 1,
      }
    default:
      return state
  }
}

export const parameters = (state = {}, action) => {
  switch (action.type) {
    case constants.PARAMETER_CONVERSATION_CREATED:
    case constants.PARAMETER_RENAMED:
    case constants.OPTION_SUGGESTED:
    case constants.OPTION_REMOVED:
      return {
        ...state,
        [action.payload.parameterId]: parameter(
          state[action.payload.parameterId],
          action
        ),
      }
    case constants.PARAMETER_SUGGESTED:
      return {
        ...state,
        [action.payload.parameter.id]: action.payload.parameter,
      }
    default:
      return state
  }
}

export const parameter = (state, action) => {
  switch (action.type) {
    case constants.PARAMETER_CONVERSATION_CREATED:
      return {
        ...state,
        conversation: action.payload.conversationId,
      }
    case constants.PARAMETER_RENAMED:
      return {
        ...state,
        name: action.payload.name,
      }
    case constants.OPTION_SUGGESTED:
      return {
        ...state,
        options: [...(state.options || []), action.payload.option.id],
      }
    case constants.OPTION_REMOVED:
      return {
        ...state,
        options: state.options.filter(id => id !== action.payload.optionId),
      }
    default:
      return state
  }
}

const entities = (state = {}, action) => {
  return {
    ...state,
    offers: offers(state.offers, action),
    options: options(state.options, action),
    responses: responses(state.responses, action),
    conversations: conversations(state.conversations, action),
    parameters: parameters(state.parameters, action),
  }
}

export default function root(state = {}, action) {
  if (action.type === constants.LOAD_ORDER) {
    return root(
      {
        ...(action.payload.merge && state),
        ...action.payload.order,
      },
      {}
    )
  }

  return {
    ...state,
    current_offer: currentOffer(state.current_offer, action),
    entry: entry(state.entry, action),
    entities: entities(state.entities, action),
  }
}
