import { connect } from "hoc/index"
import * as reactRedux from "react-redux"
import * as actions from "modules/supplier/actions"
import * as offerSelectors from "modules/supplier/selectors/offer"
import * as parameterSelectors from "modules/supplier/selectors/parameter"
import * as optionSelectors from "modules/supplier/selectors/option"
import * as orderSelectors from "modules/supplier/selectors/order"
import * as clientSelectors from "modules/supplier/selectors/client"
import * as conversationSelectors from "modules/supplier/selectors/conversation"
import * as parameterApi from "api/parameter"
import * as optionApi from "api/option"
import * as updateApi from "api/update"

export const provideRoot = connect(
  {
    parametersIds: parameterSelectors.getIds,
    isFormMode: offerSelectors.isFormMode,
  },
  (dispatch, { orderId }) => ({
    onSuggestParameter: async name => {
      const parameter = await parameterApi.suggest(orderId, name)
      dispatch(actions.parameterSuggested(parameter))
    },
  })
)

export const provideParameter = connect(
  {
    name: parameterSelectors.getName,
    role: orderSelectors.getRole,
    buyerId: clientSelectors.getId,
    conversationId: parameterSelectors.getConversationId,
    suggestedBy: parameterSelectors.getSuggestedBy,
    updates: parameterSelectors.getUpdates,
  },
  dispatch => ({
    onConversationCreated: (parameterId, conversationId) => {
      dispatch(
        actions.parameterConversationCreated(parameterId, conversationId)
      )
    },
    onNewMessage: (event, userId) => {
      const sound = new Audio("/new-message.mp3")

      if (
        event.name !== "new_message" ||
        userId === event.payload.message.sender_id
      )
        return

      sound.play()
      dispatch(actions.newUnreadMessage(event.payload.message.conversation_id))
    },
    onRemoveParameter: async parameterId => {
      await parameterApi.removeEntry(parameterId)
      dispatch(actions.parameterRemoved(parameterId))
    },
    onRenameParameter: async (parameterId, name) => {
      const parameter = await parameterApi.updateEntry(parameterId, name)
      dispatch(actions.parameterRenamed(parameterId, parameter.name))
    },
    onRemoveUpdates: async updates => {
      for (let update of updates) {
        dispatch(actions.updateRemoved(update.id))
      }
      await Promise.all(updates.map(update => updateApi.remove(update.id)))
    },
  })
)

export const provideResponseLine = reactRedux.connect(
  (state, { conversationId }) => ({
    unreadMessages:
      conversationId &&
      conversationSelectors.getUnreadMessages(state, {
        conversationId,
      }),
  })
)

const getReferences = state => {
  return {
    offers:
      state.entry.offers &&
      state.entry.offers.map(offerId => {
        return state.entities.offers[offerId]
      }),
    parameters: state.entry.parameters
      .filter(parameterId => state.entities.parameters[parameterId].options)
      .map(parameterId => {
        const parameter = state.entities.parameters[parameterId]

        return {
          name: parameter.name,
          options: parameter.options.map(
            optionId => state.entities.options[optionId]
          ),
        }
      }),
  }
}

export const provideQuickReference = connect({
  items: getReferences,
})

export const provideOptionCreation = connect(
  {
    topOffset: optionSelectors.getIds,
    index: parameterSelectors.getNextOptionIndex,
  },
  dispatch => ({
    onCreateOption: async (parameterId, name) => {
      const option = await optionApi.suggest(parameterId, name)
      dispatch(actions.optionSuggested(parameterId, option))
    },
  })
)
