import { request } from "utils/index"

export const create = (price, responseIds) =>
  request({
    url: "/offers",
    method: "POST",
    data: {
      price,
      response_ids: responseIds,
    },
  })

export const update = (offerId, price, responseIds) =>
  request({
    url: `/offers/${offerId}`,
    method: "PATCH",
    data: {
      price,
      response_ids: responseIds,
    },
  })

export const remove = offerId =>
  request({
    url: `/offers/${offerId}`,
    method: "DELETE",
  })
