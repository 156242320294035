import React from "react"
import { Layout } from "components/common/app"
import { Container } from "components/common/order"
import Provider from "modules/buyer/Provider"
import Header from "./Header"
import SuppliersHead from "./SuppliersHead"
import Offers from "./Offers"
import Parameters from "./Parameters"

export default ({ order }) => {
  return (
    <Provider order={order}>
      <Layout>
        <Header orderId={order.id} />
        <Container>
          <SuppliersHead orderId={order.id} />
          <Offers />
          <Parameters orderId={order.id} />
        </Container>
      </Layout>
    </Provider>
  )
}
