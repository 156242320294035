import { connect } from "hoc/index"
import * as optionApi from "api/option"
import * as selectors from "modules/buyer/selectors"
import * as actions from "modules/buyer/actions"
import * as updateApi from "api/update"

export const provideRoot = connect({
  optionsIds: selectors.getOptionIdsWithSuggested,
})

export const provideResponse = connect(
  {
    name: selectors.getOptionName,
    index: selectors.getOptionIndex,
    suggestedBy: selectors.getOptionSuggestedBy,
    responseStatus: selectors.getResponseStatus,
    isSelected: selectors.isResponseSelected,
    updates: selectors.getOptionUpdates,
  },
  (dispatch, { parameterId, optionId }) => ({
    onRejectOption: async () => {
      await optionApi.removeEntry(optionId)
      dispatch(actions.optionRemoved(parameterId, optionId))
    },
    onAcceptOption: async () => {
      await optionApi.acceptSuggested(optionId)
      dispatch(actions.optionAccepted(optionId))
    },
    onRemoveUpdates: async updates => {
      for (let update of updates) {
        dispatch(actions.updateRemoved(update.id))
      }
      await Promise.all(updates.map(update => updateApi.remove(update.id)))
    },
  })
)
