import shortid from "shortid"
import * as optionApi from "api/option"
import { connect } from "hoc/index"
import * as selectors from "modules/buyer/selectors"
import * as actions from "modules/buyer/actions"

export const provideRoot = connect({
  optionsIds: selectors.getOwnOptionIds,
})

export const provideOption = connect(
  {
    index: selectors.getOptionIndex,
    name: selectors.getOptionName,
    suggestedBy: selectors.getOptionSuggestedBy,
  },
  (dispatch, { parameterId, optionId }) => ({
    onRenameOption: async name => {
      const option = await optionApi.updateEntry(optionId, name)
      dispatch(actions.optionRenamed(optionId, option.name))
    },
    onRemoveOption: async () => {
      await optionApi.removeEntry(optionId)
      dispatch(actions.optionRemoved(parameterId, optionId))
    },
    onUploadFiles: async items => {
      await Promise.all(
        [...items].map(async item => {
          const tempId = shortid.generate()
          dispatch(actions.requestOptionFileUpload(optionId, tempId, item.name))
          const file = await optionApi.uploadFile(optionId, item)
          dispatch(actions.successOptionFileUpload(optionId, tempId, file))
        })
      )
    },
  })
)
