import React from "react"
import ReactTooltip from "react-tooltip"
import * as R from "ramda"
import cx from "classnames"
import { Stack, Dropdown, Menu } from "components/kit"
import { When } from "components/service"
import { OfferSelection, Label, IndexedLabel } from "components/common/order"
import squares from "assets/squares.svg"
import clock from "assets/clock.svg"
import declined from "assets/declined.svg"
import * as hoc from "./hoc"

export default hoc.provideRoot(({ optionsIds, parameterId, prio }) => (
  <Stack stretchItem direction="col" spacing="3">
    {optionsIds.map(id => (
      <Response key={id} parameterId={parameterId} optionId={id} prio={prio} />
    ))}
  </Stack>
))

const Response = hoc.provideResponse(
  ({
    status,
    name,
    index,
    prio,
    optionId,
    responseId,
    parameterId,
    isSelected,
    isOfferMode,
    onSelect,
    onRespond,
  }) => {
    const isValidForOffer = isOfferMode && responseId && status === "accepted"
    const isInvalidForOffer =
      isOfferMode && (!responseId || status !== "accepted")
    const isNotSelected = isInvalidForOffer || !isSelected

    return (
      <When
        cond={isOfferMode || isSelected}
        component={OfferSelection}
        color={isSelected ? "green" : "lightest"}
        zIndex={isSelected && prio}
        top
      >
        <Stack
          data-tip
          data-for={status || "not-provided"}
          onClick={() => isValidForOffer && onSelect(parameterId, responseId)}
          spacing="4"
          direction="row"
          alignItems="center"
          className={isValidForOffer && "cursor-pointer"}
        >
          <IndexedLabel
            theme={
              isNotSelected
                ? status === "accepted"
                  ? "gray"
                  : status === "rejected"
                  ? "red"
                  : status === "pending" && "orange"
                : "blue"
            }
            index={index}
          />
          <When
            cond={!isOfferMode}
            component={ResponseMenu}
            status={status}
            onRespond={onRespond}
          >
            {isClicked => (
              <Value
                status={status}
                optionName={name}
                optionId={optionId}
                responseId={responseId}
                isSelected={isSelected}
                isOfferMode={isOfferMode}
              />
            )}
          </When>
        </Stack>
      </When>
    )
  }
)

const ResponseMenu = ({ status, children, onRespond }) => (
  <Dropdown offset="8" renderLink={children}>
    <Menu
      items={[
        status !== "accepted" && {
          title: "Accept option as is",
          icon: squares,
          onClick: () => onRespond("accepted"),
        },
        status !== "pending" && {
          title: "Checking internally",
          icon: clock,
          onClick: () => onRespond("pending"),
        },
        status !== "rejected" && {
          title: "Can't offer this option",
          icon: declined,
          onClick: () => onRespond("rejected"),
        },
      ].filter(R.identity)}
    />
  </Dropdown>
)

const ResponseLabel = ({ theme, children }) => (
  <Label className="cursor-pointer" theme={theme}>
    {children}
  </Label>
)

const Value = ({
  status,
  optionName,
  isClicked,
  isSelected,
  isOfferMode,
  onRespond,
}) => {
  switch (status) {
    case "accepted":
      return (
        <>
          <span className="mr-3 text-darkest">{optionName}</span>
          <ReactTooltip id="accepted">Accepted</ReactTooltip>
        </>
      )
    case "rejected":
      return (
        <>
          <span className="mr-3 text-darkest">{optionName}</span>
          <ReactTooltip id="rejected">Rejected</ReactTooltip>
        </>
      )
    case "pending":
      return (
        <>
          <span className="mr-3 text-darkest">{optionName}</span>
          <ReactTooltip id="pending">Pending</ReactTooltip>
        </>
      )
    default:
      return !isOfferMode ? (
        <button
          className={cx(
            "focus:outline-none py-1 -my-1 text-mild-blue font-bold text-xs px-2 rounded",
            isClicked ? "bg-lightest" : "hover:bg-lightest"
          )}
        >
          Reply
        </button>
      ) : (
        <Label theme="gray">Not provided</Label>
      )
  }
}
