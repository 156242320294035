const empty = []

export const getIds = (state, { parameterId }) =>
  state.entities.parameters[parameterId].options || empty

export const getName = (state, { optionId }) =>
  state.entities.options[optionId].name

export const getIndex = (state, { optionId }) =>
  state.entities.options[optionId].index

export const getFilesIds = (state, { optionId }) =>
  state.entities.options[optionId].files || empty

export const getSuggestedBy = (state, { optionId }) =>
  state.entities.options[optionId].suggested_by

export const getUpdates = (state, { optionId }) =>
  state.entry.updates &&
  state.entry.updates.filter(
    update => update.type === "option" && update.entry_id === optionId
  )
