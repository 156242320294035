import React, { useContext } from "react"
import { context as modalContext } from "context/modal"
import { Stack, ContextMenu, ConfirmBox } from "components/kit"
import { IndexedLabel, LabeledInput, Updates } from "components/common/order"
import bin from "assets/bin.svg"
import FilesList from "./FilesList"
import * as hoc from "./hoc"

export default hoc.provideRoot(({ parameterId, isSuggested, optionsIds }) => {
  return (
    optionsIds && (
      <Stack direction="col" spacing="3" stretchItem>
        {optionsIds.map(id => (
          <Option key={id} parameterId={parameterId} optionId={id} />
        ))}
      </Stack>
    )
  )
})

const Option = hoc.provideOption(
  ({
    optionId,
    index,
    name,
    suggestedBy,
    updates,
    onRenameOption,
    onRemoveOption,
    onRemoveUpdates,
  }) => {
    const modal = useContext(modalContext)

    return !suggestedBy ? (
      <>
        <Stack spacing="4" direction="row" alignItems="center">
          <IndexedLabel index={index} />
          <div className="text-darkest">{name}</div>
          {updates && !!updates.length && (
            <Updates onClick={() => onRemoveUpdates(updates)} />
          )}
        </Stack>
        <FilesList optionId={optionId} />
      </>
    ) : (
      <ContextMenu
        items={[
          {
            title: "Delete option",
            icon: bin,
            onClick: () =>
              modal.open(
                <ConfirmBox
                  title="Delete this option?"
                  confirmTitle="Delete"
                  cancelTitle="Cancel"
                  onCancel={modal.close}
                  onConfirmSuccess={modal.close}
                  onConfirm={onRemoveOption}
                />
              ),
          },
        ]}
      >
        <LabeledInput
          index={index}
          text={name}
          postfix="(suggested)"
          onSaveBlur={value => onRenameOption(value)}
        />
      </ContextMenu>
    )
  }
)
