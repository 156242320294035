import React, { useContext, forwardRef } from "react"
import { Link } from "@reach/router"
import { context as modalContext } from "context/modal"
import { Avatar } from "components/kit"
import logo from "assets/logo.svg"
import { context as userContext } from "context/user"
import exit from "assets/exit.svg"
import * as paths from "paths.js"

export default ({ children }) => {
  const user = useContext(userContext)
  const modal = useContext(modalContext)

  return (
    <div className="bg-lighter min-h-screen">
      <div className="bg-white text-moist flex items-center h-20 pl-16">
        <span className="font-bold font-rubik h-full flex items-center pr-12 border-r border-paper">
          Specful
        </span>
        <div className="ml-12">
          <Link to={paths.orders} className="text-moist">
            Projects
          </Link>
        </div>
        <div className="ml-auto mr-16 flex items-center h-full">
          <div className="pr-12 mr-12 border-r border-paper h-full flex items-center">
            <a
              onClick={() => modal.open(<Help />)}
              className="text-moist cursor-pointer"
            >
              Help
            </a>
          </div>
          <Avatar image={user.data.profile.avatar} />
          {
            // <span className="ml-4 text-moist">{user.data.profile.name}</span>
          }
          <button onClick={user.removeSession} className="ml-6 w-4">
            <img src={exit} alt="" />
          </button>
        </div>
      </div>
      <div
        className="grade-line"
        style={{
          height: "4px",
        }}
      />
      {children}
    </div>
  )
}

const Help = forwardRef((props, ref) => (
  <div
    ref={ref}
    className="bg-white py-12 px-6 flex flex-wrap max-w-4xl w-full"
  >
    <HelpItem
      title="What are 'Parameters'?"
      text="Parameters are requirements, specifications, any kind of terms or anything else important about your project. Say ‘color’ is a parameter."
    />
    <HelpItem
      title="What are 'Options'?"
      text="An option is a value you set for any given parameter. In fact, you may have more than one of those. Hence the name. For example, for parameter 'color' you may have options 'red', 'green', and 'blue'."
    />
    <HelpItem
      title="What is 'Parameter Chat'?"
      text="Specful helps you structure all communication with suppliers by having separate chats where you discuss anything related to a particular parameter or topic."
    />
    <HelpItem
      title="What is 'Quick Reference'?"
      text="It's an easy way to give context to any message you send. Think of it like saying 'about THIS, here's what I have to say: {your message}'."
    />
  </div>
))

const HelpItem = ({ title, text }) => (
  <div className="w-1/2 p-6">
    <p className="text-moist font-semibold mb-3">{title}</p>
    <p className="text-moist">{text}</p>
  </div>
)
