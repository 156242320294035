import React from "react"
import { SmartInput, Stack } from "components/kit"
import IndexedLabel from "../IndexedLabel"

export default ({
  index,
  text,
  postfix,
  autoFocus,
  onSaveBlur,
  onSaveBlurSuccess,
  onCancel,
}) => (
  <Stack spacing="4" direction="row">
    <IndexedLabel index={index} />
    <SmartInput
      isEditable
      xSpace="2"
      ySpace="1"
      textColor="moist"
      hoverBg="tune"
      focusBg="tune"
      style={{
        maxWidth: "14rem",
      }}
      fontWeight="medium"
      defaultValue={text}
      postfix={postfix}
      autoFocus={autoFocus}
      onSaveBlur={onSaveBlur}
      onSaveBlurSuccess={onSaveBlurSuccess}
      onCancel={onCancel}
    />
  </Stack>
)
