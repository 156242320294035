import React, { useContext, useCallback, useState, useEffect } from "react"
import { usePersistedState } from "hooks/index"
import { context as modalContext } from "context/modal"
import { context as userContext } from "context/user"
import { context as conversationsOnlineContext } from "context/conversations-online"
import { ConfirmBox, IconLink, HoverList } from "components/kit"
import * as parameterApi from "api/parameter"
import bin from "assets/bin.svg"
import {
  Column,
  Cell,
  CellWrap,
  CellBox,
  CellHead,
  Chat,
  QuickReference,
  DiscussLink,
  ParameterCreation,
  OptionCreation,
  Updates,
} from "components/common/order"
import QuickStart from "./QuickStart"
import EmptyState from "./EmptyState"
import OptionList from "./OptionList"
import SuggestedOptionList from "./SuggestedOptionList"
import ResponseList from "./ResponseList"
import * as hoc from "./hoc"

export default hoc.provideRoot(
  ({ parametersIds, orderId, onCreateParameter }) => {
    const [isQuickStartVisible, setQuickStartVisibility] = usePersistedState(
      true,
      `buyer_quick_start_${orderId}`
    )

    const onHideQuickStart = () => setQuickStartVisibility(false)
    const onSubmitQuickStart = selection =>
      Promise.all(selection.map(onCreateParameter))

    return (
      <>
        {parametersIds.length ? (
          <div className="overflow-hidden">
            {parametersIds.map(id => (
              <div className="flex" key={id}>
                <Head parameterId={id} />
                <Tail parameterId={id} />
              </div>
            ))}
          </div>
        ) : (
          <EmptyState />
        )}
        {!parametersIds.length && isQuickStartVisible && (
          <QuickStart onSubmit={onSubmitQuickStart} onHide={onHideQuickStart} />
        )}
        <ParameterCreation title="Add parameter" onCreate={onCreateParameter} />
      </>
    )
  }
)

const Head = hoc.provideHead(
  ({
    parameterId,
    suggestedBy,
    parameterName,
    onRenameParameter,
    onRemoveParameter,
    hasSuppliers,
  }) => {
    const modal = useContext(modalContext)

    if (suggestedBy) return <Column />

    return (
      <Column>
        <Cell
          border={hasSuppliers}
          isEditable
          onSaveBlur={name => onRenameParameter(parameterId, name)}
          title={parameterName}
          contextMenu={[
            {
              title: "Delete parameter",
              icon: bin,
              onClick: () =>
                modal.open(
                  <ConfirmBox
                    title="Delete this parameter?"
                    confirmTitle="Delete"
                    cancelTitle="Cancel"
                    onCancel={modal.close}
                    onConfirmSuccess={modal.close}
                    onConfirm={() => onRemoveParameter(parameterId)}
                  />
                ),
            },
          ]}
        >
          <OptionList
            isParameterSuggested={!!suggestedBy}
            parameterId={parameterId}
          />
          <ConnectedOptionCreation parameterId={parameterId} />
        </Cell>
      </Column>
    )
  }
)

const Tail = hoc.provideTail(
  ({ parameterId, suppliersIds, suggestedBy, updates, onRemoveUpdates }) => {
    return suppliersIds.map((id, i) => {
      if (suggestedBy && id !== suggestedBy) return <Column />

      if (id === suggestedBy)
        return (
          <Suggested
            parameterId={parameterId}
            supplierId={id}
            updates={updates}
            onRemoveUpdates={() => onRemoveUpdates(updates)}
          />
        )

      return (
        <Supplier
          key={id}
          border={i !== suppliersIds.length - 1}
          parameterId={parameterId}
          supplierId={id}
        />
      )
    })
  }
)

const Suggested = hoc.provideSuggested(
  ({
    supplierId,
    parameterId,
    parameterName,
    optionsIds,
    updates,
    onRemoveParameter,
    onAcceptParameter,
    onRemoveUpdates,
  }) => {
    const modal = useContext(modalContext)

    return (
      <Column>
        <Cell
          title={parameterName}
          contextMenu={[
            {
              title: "Accept parameter",
              onClick: () =>
                modal.open(
                  <ConfirmBox
                    title="Accept this parameter?"
                    confirmTitle="Accept"
                    cancelTitle="Cancel"
                    onCancel={modal.close}
                    onConfirmSuccess={modal.close}
                    onConfirm={() => onAcceptParameter(parameterId, optionsIds)}
                  />
                ),
            },
            {
              title: "Decline parameter",
              onClick: () =>
                modal.open(
                  <ConfirmBox
                    title="Decline this parameter?"
                    confirmTitle="Decline"
                    cancelTitle="Cancel"
                    onCancel={modal.close}
                    onConfirmSuccess={modal.close}
                    onConfirm={() => onRemoveParameter(parameterId)}
                  />
                ),
            },
          ]}
          postfix={
            updates && !!updates.length && <Updates onClick={onRemoveUpdates} />
          }
        >
          <SuggestedOptionList
            supplierId={supplierId}
            parameterId={parameterId}
          />
        </Cell>
      </Column>
    )
  }
)

const Supplier = hoc.provideSupplier(
  ({
    border,
    parameterId,
    supplierId,
    conversationId,
    onConversationCreated,
    onNewMessage,
  }) => {
    const [reference, setReference] = useState()
    const conversationsUpdate = useContext(conversationsOnlineContext)
    const user = useContext(userContext)

    const createConversation = useCallback(async () => {
      const conversationId = (await parameterApi.createConversation(
        parameterId,
        supplierId
      )).conversation_id

      onConversationCreated(parameterId, supplierId, conversationId)

      return conversationId
    }, [])

    useEffect(() => {
      if (conversationId) {
        conversationsUpdate.connect(conversationId)
        conversationsUpdate.subscribe(conversationId, event =>
          onNewMessage(event, user.data.id)
        )
      }
    }, [conversationId])

    return (
      <Column>
        <Chat
          sidebar={
            <Reference
              parameterId={parameterId}
              onSelect={setReference}
              supplierId={supplierId}
            />
          }
          reference={reference}
          conversationId={conversationId}
          createConversation={() => createConversation()}
          onRemoveReference={() => setReference(void 0)}
          onClose={() => setReference(void 0)}
        >
          {({ open }) => (
            <CellWrap>
              <ResponseLine onChatOpen={open} conversationId={conversationId} />
              <CellBox border={border}>
                <ResponseList
                  supplierId={supplierId}
                  parameterId={parameterId}
                />
              </CellBox>
            </CellWrap>
          )}
        </Chat>
      </Column>
    )
  }
)

const ResponseLine = hoc.provideResponseLine(
  ({ unreadMessages, onChatOpen }) => (
    <CellHead
      leftLink={
        <DiscussLink unreadMessages={unreadMessages} onClick={onChatOpen} />
      }
    />
  )
)

const Reference = hoc.provideQuickReference(QuickReference)

const ConnectedOptionCreation = hoc.provideOptionCreation(OptionCreation)
