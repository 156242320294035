import { connect } from "hoc/index"
import * as optionSelectors from "modules/supplier/selectors/option"
import * as actions from "modules/supplier/actions"
import * as optionApi from "api/option"
import * as updateApi from "api/update"

export const provideRoot = connect({
  optionsIds: optionSelectors.getIds,
})

export const provideOption = connect(
  {
    index: optionSelectors.getIndex,
    name: optionSelectors.getName,
    suggestedBy: optionSelectors.getSuggestedBy,
    updates: optionSelectors.getUpdates,
  },
  (dispatch, { optionId, parameterId }) => ({
    onRemoveOption: async () => {
      await optionApi.removeEntry(optionId)
      dispatch(actions.optionRemoved(parameterId, optionId))
    },
    onRenameOption: async name => {
      const option = await optionApi.updateEntry(optionId, name)
      dispatch(actions.optionRenamed(optionId, option.name))
    },
    onRemoveUpdates: async updates => {
      for (let update of updates) {
        dispatch(actions.updateRemoved(update.id))
      }
      await Promise.all(updates.map(update => updateApi.remove(update.id)))
    },
  })
)
