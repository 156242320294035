import React, { useEffect, useContext } from "react"
import { parse } from "query-string"
import { PageLoader } from "components/kit"
import * as userApi from "api/user"
import * as userContext from "context/user"

export default () => {
  const { createSession } = useContext(userContext.context)

  // TODO: handle back-end failure response
  useEffect(() => {
    const { code } = parse(document.location.search)

    userApi.googleSignIn(code).then(createSession)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <PageLoader />
}
