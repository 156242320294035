import { connect } from "hoc/index"
import * as optionApi from "api/option"
import * as selectors from "modules/buyer/selectors"
import * as actions from "modules/buyer/actions"

export const provideRoot = connect({
  filesIds: selectors.getOptionFilesIds,
  uploadingFiles: selectors.getOptionUploadingFiles,
})

export const provideFile = connect(
  {
    name: selectors.getFileOriginalName,
    url: selectors.getFileUrl,
  },
  (dispatch, { optionId, fileId }) => ({
    onRemove: async () => {
      await optionApi.removeFile(optionId, fileId)
      dispatch(actions.optionFileRemoved(optionId, fileId))
    },
  })
)
