import React, { useState } from "react"
import { Button } from "components/kit"

export default React.forwardRef(
  (
    { title, confirmTitle, cancelTitle, onConfirm, onConfirmSuccess, onCancel },
    ref
  ) => {
    const [isConfirming, setConfirming] = useState(false)

    return (
      <div className="bg-white p-12 max-w-md w-full shadow" ref={ref}>
        <p className="text-darkest text-2xl mb-12">{title}</p>
        <div className="flex -mx-2">
          <div className="px-2 flex-1">
            <Button theme="secondary" full onClick={onCancel}>
              {cancelTitle}
            </Button>
          </div>
          <div className="px-2 flex-1">
            <Button
              full
              isSpinning={isConfirming}
              onClick={async () => {
                setConfirming(true)
                await onConfirm()
                setConfirming(false)
                onConfirmSuccess()
              }}
            >
              {confirmTitle}
            </Button>
          </div>
        </div>
      </div>
    )
  }
)
