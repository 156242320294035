import { createSelector } from "reselect"

const empty = []

export const hasOffers = state => !!Object.keys(state.entities.offers).length
export const getOffersIds = (state, { supplierId }) =>
  state.entities.suppliers[supplierId].offers || empty
export const getOfferPrice = (state, { offerId }) =>
  state.entities.offers[offerId].price

export const getOrderName = state => state.entry.name
export const getOrderId = state => state.entry.id

export const getInvitationsIds = state => state.entry.invitations || empty
export const getInvitationEmail = (state, { invitationId }) =>
  state.entities.invitations[invitationId].email
export const getInvitationDate = (state, { invitationId }) =>
  state.entities.invitations[invitationId].created_at

export const getSupplier = (state, { supplierId }) =>
  state.entities.suppliers[supplierId]
export const getSuppliersIds = state => state.entry.suppliers || empty
export const hasSuppliers = state => !!state.entry.suppliers
export const getSupplierName = (state, { supplierId }) =>
  state.entities.suppliers[supplierId].name
export const getSupplierCompany = (state, { supplierId }) =>
  state.entities.suppliers[supplierId].company
export const getSupplierConversationId = (state, { supplierId }) =>
  state.entities.suppliers[supplierId].conversation
export const getSupplierResponsesIds = (state, { supplierId }) =>
  state.entities.suppliers[supplierId].responses

export const hasParameters = state =>
  !!Object.keys(state.entities.parameters).length
export const getParametersIds = state =>
  (state.entry.parameters &&
    [...state.entry.parameters].sort((a, b) => {
      const aUpdates = getParameterUpdates(state, { parameterId: a })
      const bUpdates = getParameterUpdates(state, { parameterId: b })
      const hasAUpdates = aUpdates && !!aUpdates.length
      const hasBUpdates = bUpdates && !!bUpdates.length

      if (hasAUpdates && !hasBUpdates) return -1
      if (!hasAUpdates && hasBUpdates) return 1
    })) ||
  empty
export const getParameterName = (state, props) =>
  state.entities.parameters[props.parameterId].name
export const getParameterSuggestedBy = (state, props) =>
  state.entities.parameters[props.parameterId].suggested_by
export const getParameterConversationId = (state, props) => {
  const items =
    state.entities.suppliers[props.supplierId].parameters_conversations

  return (
    items &&
    items.find(
      id => state.entities.conversations[id].parameter_id === props.parameterId
    )
  )
}
export const getParameterUpdates = (state, { parameterId }) =>
  state.entry.updates &&
  state.entry.updates.filter(
    update => update.type === "parameter" && update.entry_id === parameterId
  )

export const getConversationUnreadMessages = (state, props) =>
  state.entities.conversations[props.conversationId].unread_messages
export const getOwnOptionIds = (state, { parameterId }) => {
  if (state.entities.parameters[parameterId].options) {
    return state.entities.parameters[parameterId].options.filter(
      id => !state.entities.options[id].suggested_by
    )
  }

  return empty
}
export const getOptionIdsWithSuggested = (
  state,
  { parameterId, supplierId }
) => {
  if (state.entities.parameters[parameterId].options) {
    return state.entities.parameters[parameterId].options.filter(
      id =>
        !state.entities.options[id].suggested_by ||
        state.entities.options[id].suggested_by === supplierId
    )
  }

  return empty
}

export const getOptionsIds = (state, { parameterId }) =>
  state.entities.parameters[parameterId].options || empty
export const getOptionName = (state, { optionId }) =>
  state.entities.options[optionId].name
export const getOptionIndex = (state, { optionId }) =>
  state.entities.options[optionId].index
export const getOptionSuggestedBy = (state, { optionId }) =>
  state.entities.options[optionId].suggested_by
export const getNextOptionIndex = (state, { parameterId }) =>
  state.entities.parameters[parameterId].options
    ? state.entities.parameters[parameterId].options.length + 1
    : 1
export const getOptionFilesIds = (state, { optionId }) =>
  state.entities.options[optionId].files || empty
export const getOptionUploadingFiles = (state, { optionId }) =>
  state.options_uploading_files[optionId] || empty
export const getOptionUpdates = (state, { optionId }) =>
  state.entry.updates &&
  state.entry.updates.filter(
    update => update.type === "option" && update.entry_id === optionId
  )

export const getFile = (state, { fileId }) => state.entities.files[fileId]
export const getFileOriginalName = createSelector(
  [getFile],
  file => file.original_name
)
export const getFileUrl = createSelector(
  [getFile],
  file => file.url
)

export const getSupplierResponseIds = (state, { supplierId }) =>
  state.entities.suppliers[supplierId].responses

export const getResponse = (state, { optionId, supplierId }) => {
  const supplier = state.entities.suppliers[supplierId]

  return (
    supplier.responses &&
    supplier.responses
      .map(id => state.entities.responses[id])
      .find(response => response.option_id === optionId)
  )
}

export const getResponseStatus = createSelector(
  [getResponse],
  response => response && response.status
)

export const getSelectedOffer = (state, { supplierId }) =>
  state.entities.offers[
    state.selected_offers[supplierId] ||
      (state.entities.suppliers[supplierId].offers &&
        state.entities.suppliers[supplierId].offers[0])
  ]

export const isOfferSelected = createSelector(
  [getSelectedOffer, (_, props) => props.offerId],
  (selectedOffer, offerId) => selectedOffer.id === offerId
)

export const isResponseSelected = createSelector(
  [getSelectedOffer, getResponse],
  (selectedOffer, response) =>
    selectedOffer &&
    selectedOffer.selections.find(
      item => item.response_id === (response && response.id)
    )
)
