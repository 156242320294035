import React, { forwardRef } from "react"
import Button from "../Button"

export default forwardRef(
  ({ children, title, confirmTitle, isSubmitting, onCancel }, ref) => (
    <div
      ref={ref}
      className="bg-white pane-shadow p-12 text-darkest max-w-2xl w-full"
    >
      <p className="leading-none mb-12 font-bold text-2xl">{title}</p>
      {children}
      <div className="mt-12 flex justify-end">
        <Button type="button" theme="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <div className="ml-3">
          <Button isSpinning={isSubmitting}>{confirmTitle}</Button>
        </div>
      </div>
    </div>
  )
)
