import React from "react"
import { Field } from "formik"

export default ({ name, component: Component, ...props }) => (
  <Field
    name={name}
    render={({ field, form: { errors, touched } }) => {
      const error = errors[field.name]
      const isTouched = touched[field.name]

      return <Component {...field} {...props} error={isTouched && error} />
    }}
  />
)
