import React, { useEffect, useContext } from "react"
import { Redirect } from "@reach/router"
import * as userContext from "context/user"
import * as userApi from "api/user"
import * as utils from "./utils"

export default ({ children, guard, user, loader }) => {
  const isLoaded = !!user.data
  const { removeSession } = useContext(userContext.context)

  useEffect(() => {
    if (!isLoaded) {
      userApi
        .fetchUserData()
        .then(user.setData)
        .catch(err => {
          if (err.response.status >= 400 && err.response.status < 500) {
            removeSession()

            return
          }

          throw err
        })
    }
  }, [isLoaded, user])

  if (!isLoaded) return loader || <div />

  if (!guard(true, user.data)) {
    return (
      <Redirect
        noThrow
        to={user.forceRedirect || utils.redirectTo(true, user.data, user)}
      />
    )
  }

  return children
}
