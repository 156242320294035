import React, { useState, useRef } from "react"
import cx from "classnames"
import { useClickOutside } from "hooks/index"

export default ({ children, offset = 0, renderLink, link: Link }) => {
  const [isVisible, setVisible] = useState(false)
  const ref = useRef()
  const link = renderLink ? (
    renderLink(isVisible)
  ) : (
    <Link isVisible={isVisible} />
  )

  useClickOutside(ref, () => setVisible(false))

  return (
    <div ref={ref} onClick={() => setVisible(!isVisible)} className="relative">
      {link}
      {isVisible && (
        <div className={cx("absolute top-0 left-0 z-10", `mt-${offset}`)}>
          {children}
        </div>
      )}
    </div>
  )
}
