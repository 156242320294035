import * as R from "ramda"
import { connect } from "hoc/index"
import * as optionSelectors from "modules/supplier/selectors/option"
import * as responseSelectors from "modules/supplier/selectors/response"
import * as offerSelectors from "modules/supplier/selectors/offer"
import * as actions from "modules/supplier/actions"
import * as responseApi from "api/response"

export const provideRoot = connect({
  optionsIds: optionSelectors.getIds,
})

export const provideResponse = R.compose(
  connect(
    {
      name: optionSelectors.getName,
      index: optionSelectors.getIndex,
      status: responseSelectors.getStatus,
      responseId: responseSelectors.getId,
      isSelected: offerSelectors.isResponseSelected,
      isOfferMode: offerSelectors.isFormMode,
    },
    dispatch => ({
      onSelect: (parameterId, responseId) =>
        dispatch(actions.selectResponse(parameterId, responseId)),
    })
  ),
  connect(
    {},
    (dispatch, { optionId, responseId }) => ({
      onRespond: async status => {
        const response = !responseId
          ? await responseApi.createEntry(optionId, { status })
          : await responseApi.updateEntry(responseId, { status })

        dispatch(actions.successRespond(optionId, response))
      },
    })
  )
)
