import React, { useEffect, useContext, useState, useCallback } from "react"
import moment from "moment"
import * as orderApi from "api/order"
import { context as modalContext } from "context/modal"
import { context as userContext } from "context/user"
import { context as conversationsOnlineContext } from "context/conversations-online"
import { ContextMenu, ConfirmBox } from "components/kit"
import { Column, Cell, DiscussLink, Chat } from "components/common/order"
import bin from "assets/bin.svg"
import * as hoc from "./hoc"

export default ({ orderId }) => (
  <div className="flex">
    <Column className="flex-shrink-0" />
    <Suppliers orderId={orderId} />
    <Invitations />
  </div>
)

const Suppliers = hoc.provideSuppliers(
  ({ orderId, suppliersIds, onRemoveSupplier }) =>
    suppliersIds.map(id => {
      const modal = useContext(modalContext)

      return (
        <Item
          orderId={orderId}
          supplierId={id}
          contextMenu={[
            {
              title: "Delete supplier",
              icon: bin,
              onClick: () =>
                modal.open(
                  <ConfirmBox
                    title="Delete this supplier from the offer?"
                    confirmTitle="Delete"
                    cancelTitle="Cancel"
                    onCancel={modal.close}
                    onConfirmSuccess={modal.close}
                    onConfirm={() => onRemoveSupplier(id)}
                  />
                ),
            },
          ]}
          key={id}
        >
          <Supplier supplierId={id} />
        </Item>
      )
    })
)

const Invitations = hoc.provideInvitations(
  ({ invitationsIds, onRemoveInvitation }) =>
    invitationsIds.map(id => {
      const modal = useContext(modalContext)

      return (
        <Column key={id}>
          <ContextMenu
            offset="2"
            verticalAlign="start"
            items={[
              {
                title: "Delete invitation",
                icon: bin,
                onClick: () =>
                  modal.open(
                    <ConfirmBox
                      title="Delete this invitation from the offer?"
                      confirmTitle="Delete"
                      cancelTitle="Cancel"
                      onCancel={modal.close}
                      onConfirmSuccess={modal.close}
                      onConfirm={() => onRemoveInvitation(id)}
                    />
                  ),
              },
            ]}
          >
            <Invitation invitationId={id} />
          </ContextMenu>
        </Column>
      )
    })
)

const Supplier = hoc.provideSupplier(({ name, company }) => (
  <InfoWrap>
    <span className="font-bold">{name}</span>
    <span>{company}</span>
  </InfoWrap>
))

export const Invitation = hoc.provideInvitation(({ email, date }) => (
  <div className="flex flex-col p-6 bg-white pane-shadow">
    <p className="font-bold">{email}</p>
    <p>Invited {moment(date).fromNow()}</p>
  </div>
))

const InfoWrap = ({ children }) => (
  <div className="flex flex-col p-6 -m-6">{children}</div>
)

const Item = hoc.provideSupplier(
  ({
    conversationId,
    children,
    contextMenu,
    supplierId,
    orderId,
    onNewMessage,
  }) => {
    const conversationsUpdate = useContext(conversationsOnlineContext)
    const user = useContext(userContext)
    const [reference, setReference] = useState()
    const createConversation = useCallback(
      async () =>
        (await orderApi.createConversation(orderId, supplierId))
          .conversation_id,
      []
    )

    useEffect(() => {
      if (conversationId) {
        conversationsUpdate.connect(conversationId)
        conversationsUpdate.subscribe(conversationId, event =>
          onNewMessage(event, user.data.id)
        )
      }
    }, [conversationId])

    return (
      <Chat
        reference={reference}
        conversationId={conversationId}
        createConversation={createConversation}
        onRemoveReference={() => setReference(void 0)}
        onClose={() => setReference(void 0)}
      >
        {({ open }) => (
          <Column>
            <Cell
              leftLink={
                <ConnectedDiscussLink
                  conversationId={conversationId}
                  onClick={open}
                  title="Chat"
                />
              }
              contextMenu={contextMenu}
            >
              {children}
            </Cell>
          </Column>
        )}
      </Chat>
    )
  }
)

const ConnectedDiscussLink = hoc.provideDiscussLink(DiscussLink)
