import { connect } from "hoc/index"
import * as selectors from "modules/buyer/selectors"
import * as actions from "modules/buyer/actions"
import * as invitationApi from "api/invitation"
import * as orderApi from "api/order"

export const provideRoot = connect(
  {
    orderName: selectors.getOrderName,
    hasParameters: selectors.hasParameters,
  },
  (dispatch, { orderId }) => {
    return {
      onInvite: async data => {
        const invitation = await invitationApi.createEntry(orderId, data)
        dispatch(actions.invitationCreated(invitation))
      },
      onRename: async name => {
        const order = await orderApi.updateEntry(orderId, name)
        dispatch(actions.orderRenamed(order.name))
      },
    }
  }
)
