import { connect } from "hoc/index"
import * as optionSelectors from "modules/supplier/selectors/option"
import * as fileSeletors from "modules/supplier/selectors/file"

export const provideRoot = connect({
  filesIds: optionSelectors.getFilesIds,
})

export const provideFile = connect({
  name: fileSeletors.getOriginalName,
  url: fileSeletors.getUrl,
})
