import React, { useContext } from "react"
import { context as modalContext } from "context/modal"
import { Form, Formik } from "formik"
import { When, Field } from "components/service"
import {
  Stack,
  SmartInput,
  Button,
  ContextMenu,
  ConfirmBox,
} from "components/kit"
import {
  OfferSelection,
  Column,
  Cell,
  Label,
  CreateButton,
} from "components/common/order"
import bin from "assets/bin.svg"
import * as hoc from "./hoc"

export default hoc.provideRoot(
  ({ role, offersIds, isCreationActive, onOpenCreation, onClose }) =>
    role === "supplier" && (
      <div className="flex overflow-hidden">
        <Column>
          <Cell border title="Offers" />
        </Column>
        <Column width="35vw">
          <Cell>
            <Stack direction="col" spacing="3" stretchItem>
              {offersIds.map(id => (
                <OfferItem key={id} offerId={id} onCancel={onClose} />
              ))}
              {!isCreationActive ? (
                <CreateButton onClick={onOpenCreation}>+ Offer</CreateButton>
              ) : (
                <OfferCreation
                  isSelected
                  autoFocus
                  submitTitle="Create offer"
                  onCancel={onClose}
                />
              )}
            </Stack>
          </Cell>
        </Column>
      </div>
    )
)

const OfferView = ({
  price,
  isSelected,
  autoFocus,
  submitTitle,
  isOfferCompleted,
  onClick,
  onCancel,
  onSubmit,
}) => {
  return (
    <When cond={isSelected} component={OfferSelection} bottom>
      <Formik
        onSubmit={({ price }) => onSubmit(price)}
        initialValues={{ price: price || "" }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Stack alignItems="center" direction="row" spacing="4">
              <Stack
                alignItems="center"
                direction="row"
                spacing="4"
                className="cursor-pointer"
                onClick={() => !isSelected && onClick()}
              >
                <Label theme={!isSelected ? "gray" : "bright"}>Offer A</Label>
                <span className="flex items-center text-darkest">
                  $
                  <Field
                    shouldUpdate={testNumber}
                    isEditable={isSelected}
                    autoFocus={autoFocus}
                    xSpace="2"
                    ySpace="1"
                    textColor="darkest"
                    hoverBg="lighter"
                    focusBg="light-blue"
                    bg="lighter"
                    name="price"
                    component={SmartInput}
                  />
                </span>
              </Stack>
              {isSelected && (
                <div className="ml-1 -my-1">
                  <Stack direction="row" spacing="3">
                    <Button
                      isSpinning={isSubmitting}
                      disabled={!isOfferCompleted || !values.price}
                      size="small"
                    >
                      {submitTitle}
                    </Button>
                    <Button
                      type="button"
                      onClick={onCancel}
                      theme="secondary"
                      size="small"
                    >
                      Cancel
                    </Button>
                  </Stack>
                </div>
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </When>
  )
}

const OfferItemView = ({ onRemove, ...props }) => {
  const modal = useContext(modalContext)

  return (
    <ContextMenu
      items={[
        {
          title: "Delete offer",
          icon: bin,
          onClick: () =>
            modal.open(
              <ConfirmBox
                title="Delete this offer?"
                confirmTitle="Delete"
                cancelTitle="Cancel"
                onCancel={modal.close}
                onConfirmSuccess={modal.close}
                onConfirm={() => onRemove(props.offerId)}
              />
            ),
        },
      ]}
    >
      <OfferView submitTitle="Save offer" {...props} />
    </ContextMenu>
  )
}

const OfferCreation = hoc.provideOfferCreation(OfferView)
const OfferItem = hoc.provideOfferItem(OfferItemView)

const testNumber = str => !isNaN(+str)
