import { connect } from "hoc/index"
import * as selectors from "modules/buyer/selectors"
import * as actions from "modules/buyer/actions"

export const provideRoot = connect({
  hasOffers: selectors.hasOffers,
  suppliersIds: selectors.getSuppliersIds,
})

export const provideOffers = connect({
  offersIds: selectors.getOffersIds,
})

export const provideOffer = connect(
  {
    price: selectors.getOfferPrice,
    isSelected: selectors.isOfferSelected,
  },
  (dispatch, { supplierId, offerId }) => ({
    onSelect: () => dispatch(actions.selectOffer(supplierId, offerId)),
  })
)
