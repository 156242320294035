import React from "react"
import cx from "classnames"
import Spinner from "../Spinner"

export default ({
  type = "submit",
  size = "base",
  theme = "primary",
  disabled = false,
  isSpinning = false,
  icon,
  full,
  children,
  ...props
}) => (
  <button
    {...props}
    type={type}
    disabled={disabled}
    className={cx(
      `whitespace-no-wrap relative flex items-center justify-center font-bold rounded focus:outline-none focus:shadow-outline ` +
        sizes[size],
      disabled
        ? "bg-lightest text-midgray cursor-not-allowed"
        : themes[theme].background,
      full && "w-full"
    )}
  >
    {isSpinning ? (
      <span className="mr-4">
        <Spinner color={themes[theme].spinner} />
      </span>
    ) : (
      icon && <img className="mr-4" src={icon} alt="" />
    )}
    {children}
  </button>
)

const sizes = {
  base: "h-12 px-8 text-base",
  small: "h-8 px-4 text-xs",
}

const themes = {
  primary: {
    background: "bg-nectar text-white",
    spinner: "white",
  },
  secondary: {
    background: "bg-cold hover:bg-tune text-centaur",
    spinner: "darkest",
  },
  inversed: {
    background: "bg-white text-moist hover:bg-lighter",
    spinner: "mosit",
  },
}
