import React from "react"
import { Stack } from "components/kit"
import { Scrollbar } from "components/service"
import { Label, IndexedLabel } from "components/common/order"

export default ({ title, items, onSelect }) => (
  <div
    style={{ width: "384px" }}
    className="bg-white h-full p-12 flex flex-col"
  >
    <div
      className="pb-12 mb-6 px-12 -mx-12 border-b text-xl text-bright-blue font-semibold"
      style={{ borderColor: "#F0F3F7" }}
    >
      {title}
    </div>
    <Scrollbar className="h-full -mb-6">
      <div className="uppercase text-lightest text-xs font-semibold mb-10 pt-6">
        Quick reference
      </div>
      {items.offers && (
        <div className="mb-12">
          <div className="text-mild-blue mb-6 font-bold">Offers</div>
          <Stack stretchItem direction="col" spacing="3">
            {items.offers.map(offer => (
              <Stack
                alignItems="center"
                direction="row"
                spacing="4"
                className="cursor-pointer px-2 -mx-2 py-2 -my-2 rounded hover:bg-light-blue"
                onClick={() =>
                  onSelect({
                    type: "offer",
                    data: { id: offer.id, price: offer.price },
                  })
                }
              >
                <Label>Offer A</Label>
                <span className="text-darkest">${offer.price}</span>
              </Stack>
            ))}
          </Stack>
        </div>
      )}
      {items.parameters.map(parameter => (
        <div className="mb-6">
          <div className="text-mild-blue mb-6 font-bold">{parameter.name}</div>
          <Stack stretchItem direction="col" spacing="3">
            {parameter.options.map(option => (
              <Stack
                direction="row"
                spacing="4"
                className="cursor-pointer px-2 -mx-2 py-2 -my-2 rounded hover:bg-light-blue"
                onClick={() =>
                  onSelect({
                    type: "option",
                    data: {
                      id: option.id,
                      name: parameter.name,
                      value: option.name,
                      index: option.index,
                    },
                  })
                }
              >
                <IndexedLabel index={option.index} />
                <span className="text-darkest word-break">{option.name}</span>
              </Stack>
            ))}
          </Stack>
        </div>
      ))}
    </Scrollbar>
  </div>
)
