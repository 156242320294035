import React, { useContext } from "react"
import { Button } from "components/kit"
import { Header, OrderName } from "components/common/order"
import { context as modalContext } from "context/modal"
import InvitationForm from "./InvitationForm"
import * as hoc from "./hoc"

export default hoc.provideRoot(
  ({ orderName, hasParameters, onInvite, onRename }) => {
    const modal = useContext(modalContext)

    return (
      <Header>
        <OrderName isEditable name={orderName} onSaveBlur={onRename} />
        {hasParameters && (
          <div className="ml-auto">
            <Button
              onClick={() =>
                modal.open(
                  <InvitationForm
                    onSubmit={onInvite}
                    onSubmitSuccess={modal.close}
                    onCancel={modal.close}
                  />
                )
              }
            >
              Add supplier
            </Button>
          </div>
        )}
      </Header>
    )
  }
)
