import * as constants from "./constants"

export default (state = {}, action) => {
  return {
    ...state,
    entry: entry(state.entry, action),
    entities: entities(state.entities, action),
    selected_offers: selectedOffers(state.selected_offers, action),
    options_uploading_files: optionsUploadingFiles(
      state.options_uploading_files,
      action
    ),
  }
}

export const entry = (state, action) => {
  switch (action.type) {
    case constants.ORDER_RENAMED:
      return { ...state, name: action.payload.name }
    case constants.PARAMETER_CREATED:
      return {
        ...state,
        parameters: state.parameters
          ? [...state.parameters, action.payload.parameter.id]
          : [action.payload.parameter.id],
      }
    case constants.PARAMETER_REMOVED:
      return {
        ...state,
        parameters:
          state.parameters &&
          state.parameters.filter(id => id !== action.payload.parameterId),
      }
    case constants.SUPPLIER_REMOVED:
      return {
        ...state,
        suppliers:
          state.suppliers &&
          state.suppliers.filter(id => id !== action.payload.supplierId),
      }
    case constants.INVITATION_REMOVED:
      return {
        ...state,
        invitations:
          state.invitations &&
          state.invitations.filter(id => id !== action.payload.invitationId),
      }
    case constants.INVITATION_CREATED:
      return {
        ...state,
        invitations: state.invitations
          ? [...state.invitations, action.payload.invitation.id]
          : [action.payload.invitation.id],
      }
    case constants.UPDATE_REMOVED:
      return {
        ...state,
        updates: state.updates.filter(
          update => update.id !== action.payload.updateId
        ),
      }
    default:
      return state
  }
}

export const entities = (state = {}, action) => {
  return {
    ...state,
    parameters: parameters(state.parameters, action),
    options: options(state.options, action),
    invitations: invitations(state.invitations, action),
    files: files(state.files, action),
    conversations: conversations(state.conversations, action),
    suppliers: suppliers(state.suppliers, action),
    offers: state.offers || {},
  }
}

const suppliers = (state = {}, action) => {
  switch (action.type) {
    case constants.PARAMETER_CONVERSATION_CREATED:
      return {
        ...state,
        [action.payload.supplierId]: supplier(
          state[action.payload.supplierId],
          action
        ),
      }
    case constants.PARAMETER_CREATED:
    case constants.PARAMETER_ACCEPTED:
      return {
        ...state,
        ...action.payload.conversationIds.reduce(
          (acc, item) => ({
            ...acc,
            [item.supplierId]: {
              ...state[item.supplierId],
              parameters_conversations: [
                ...(state[item.supplierId].parameters_conversations || []),
                item.conversationId,
              ],
            },
          }),
          {}
        ),
      }
    default:
      return state
  }
}

const supplier = (state = {}, action) => {
  switch (action.type) {
    case constants.PARAMETER_CONVERSATION_CREATED:
      return {
        ...state,
        parameters_conversations: [
          ...(state.parameters_conversations || []),
          action.payload.conversationId,
        ],
      }
    default:
      return state
  }
}

export const parameters = (state = {}, action) => {
  switch (action.type) {
    case constants.PARAMETER_RENAMED:
    case constants.OPTION_CREATED:
    case constants.OPTION_REMOVED:
      return {
        ...state,
        [action.payload.parameterId]: parameter(
          state[action.payload.parameterId],
          action
        ),
      }
    case constants.PARAMETER_CREATED:
    case constants.PARAMETER_ACCEPTED:
      return {
        ...state,
        [action.payload.parameter.id]: {
          ...state[action.payload.parameter.id],
          ...action.payload.parameter,
        },
      }
    default:
      return state
  }
}

export const parameter = (state, action) => {
  switch (action.type) {
    case constants.PARAMETER_RENAMED:
      return {
        ...state,
        name: action.payload.name,
      }
    case constants.OPTION_CREATED:
      return {
        ...state,
        options: state.options
          ? [...state.options, action.payload.option.id]
          : [action.payload.option.id],
      }
    case constants.OPTION_REMOVED:
      return {
        ...state,
        options:
          state.options &&
          state.options.filter(id => id !== action.payload.optionId),
      }
    default:
      return state
  }
}

export const options = (state = {}, action) => {
  switch (action.type) {
    case constants.OPTION_CREATED:
      return {
        ...state,
        [action.payload.option.id]: action.payload.option,
      }
    case constants.OPTION_FILE_REMOVED:
    case constants.OPTION_FILE_UPLOAD_SUCCESS:
    case constants.OPTION_RENAMED:
    case constants.OPTION_ACCEPTED:
      return {
        ...state,
        [action.payload.optionId]: option(
          state[action.payload.optionId],
          action
        ),
      }
    default:
      return state
  }
}

export const option = (state, action) => {
  switch (action.type) {
    case constants.OPTION_RENAMED:
      return {
        ...state,
        name: action.payload.name,
      }
    case constants.OPTION_ACCEPTED:
      return {
        ...state,
        suggested_by: null,
      }
    case constants.OPTION_FILE_REMOVED:
      return {
        ...state,
        files: state.files.filter(id => id !== action.payload.fileId),
      }
    case constants.OPTION_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        files: [...(state.files || []), action.payload.file.id],
      }
    default:
      return state
  }
}

export const invitations = (state, action) => {
  switch (action.type) {
    case constants.INVITATION_CREATED:
      return {
        ...state,
        [action.payload.invitation.id]: action.payload.invitation,
      }
    default:
      return state
  }
}

export const files = (state, action) => {
  switch (action.type) {
    case constants.OPTION_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        [action.payload.file.id]: action.payload.file,
      }
    default:
      return state
  }
}

export const conversations = (state = {}, action) => {
  switch (action.type) {
    case "READ_MESSAGES":
    case constants.NEW_UNREAD_MESSAGE:
      return {
        ...state,
        [action.payload.conversationId]: conversation(
          state[action.payload.conversationId],
          action
        ),
      }
    case constants.PARAMETER_CONVERSATION_CREATED:
      return {
        ...state,
        [action.payload.conversationId]: {
          id: action.payload.conversationId,
          parameter_id: action.payload.parameterId,
        },
      }
    case constants.PARAMETER_CREATED:
    case constants.PARAMETER_ACCEPTED:
      return {
        ...state,
        ...action.payload.conversationIds.reduce(
          (acc, item) => ({
            ...acc,
            [item.conversationId]: {
              id: item.conversationId,
              parameter_id: action.payload.parameter.id,
            },
          }),
          {}
        ),
      }
    default:
      return state
  }
}

export const conversation = (state, action) => {
  switch (action.type) {
    case "READ_MESSAGES":
      return {
        ...state,
        unread_messages: state.unread_messages - action.payload.count,
      }
    case constants.NEW_UNREAD_MESSAGE:
      return {
        ...state,
        unread_messages: (state.unread_messages || 0) + 1,
      }
    default:
      return state
  }
}

export const selectedOffers = (state = {}, action) => {
  switch (action.type) {
    case constants.SELECT_OFFER:
      return {
        ...state,
        [action.payload.supplierId]: action.payload.offerId,
      }
    default:
      return state
  }
}

export const optionsUploadingFiles = (state = {}, action) => {
  switch (action.type) {
    case constants.OPTION_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        [action.payload.optionId]: [
          ...(state[action.payload.optionId] || []),
          { id: action.payload.tempFileId, name: action.payload.fileName },
        ],
      }
    case constants.OPTION_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        [action.payload.optionId]: state[action.payload.optionId].filter(
          item => item.id !== action.payload.tempFileId
        ),
      }
    default:
      return state
  }
}
