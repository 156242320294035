import { request } from "utils/index"

export const localSignIn = ({ email, password }) =>
  request({
    url: "/me/session/local",
    method: "POST",
    data: {
      email,
      password,
    },
  })

export const googleSignIn = code =>
  request({
    url: "/me/session/google",
    method: "POST",
    data: {
      code,
    },
  })

export const setupProfile = ({ avatar, name, company }) =>
  request({
    url: "/me/profile",
    method: "POST",
    data: {
      avatar,
      name,
      company,
    },
  })

export const fetchUserData = () =>
  request({
    url: "/me",
    method: "GET",
  })

export const signUp = ({ email, password }) =>
  request({
    url: "/users",
    method: "POST",
    data: {
      email,
      password,
    },
  })
