import React, { createContext, useState, useRef } from "react"
import { useClickOutside } from "hooks/index"

export const context = createContext()

export const Provider = ({ children }) => {
  const [modal, setModal] = useState()
  const ref = useRef()
  const close = () => setModal(null)

  useClickOutside(ref, close)

  return (
    <context.Provider
      value={{
        open: setModal,
        close,
      }}
    >
      {children}
      {modal && (
        <div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-10"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
        >
          {React.cloneElement(modal, { ref })}
        </div>
      )}
    </context.Provider>
  )
}
