import React from "react"
import cx from "classnames"

export default ({ children, theme = "gray", squared = false, className }) => {
  return (
    <span
      className={cx(
        "rounded-full h-6 flex items-center text-xs",
        squared ? "justify-center w-6 uppercase" : "px-2",
        themes[theme],
        className
      )}
    >
      {children}
    </span>
  )
}

const themes = {
  bright: "bg-green text-white",
  blue: "bg-light-sky text-dark-sky",
  gray: "bg-cold text-centaur",
  orange: "bg-light-orange text-dark-orange",
  red: "bg-light-red text-dark-red",
}
