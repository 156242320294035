import request from "axios"

const { REACT_APP_API_HOST } = process.env

export default async input => {
  return (await request({
    ...input,
    url: REACT_APP_API_HOST + "/v1" + input.url,
    withCredentials: true,
  })).data
}
