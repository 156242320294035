import React, { useState } from "react"
import { Button } from "components/kit"
import { CellHead } from "components/common/order"

export default ({ title, onCreate }) => {
  const [isCreating, setCreating] = useState(false)

  return !isCreating ? (
    <div className="mt-6">
      <Button onClick={() => setCreating(true)} theme="primary">
        {title}
      </Button>
    </div>
  ) : (
    <div className="mb-12">
      <CellHead
        autoFocus
        isEditable
        onSaveBlur={onCreate}
        onSaveBlurSuccess={() => setCreating(false)}
        onCancel={() => setCreating(false)}
      />
    </div>
  )
}
