import React, { useContext } from "react"
import { context as modalContext } from "context/modal"
import { ContextMenu, Stack, ConfirmBox, UploadDialog } from "components/kit"
import { LabeledInput } from "components/common/order"
import bin from "assets/bin.svg"
import attachment from "assets/attachment.svg"
import * as hoc from "./hoc"
import FilesList from "./FilesList"

export default hoc.provideRoot(({ optionsIds, parameterId }) => {
  return (
    optionsIds && (
      <Stack direction="col" spacing="3" stretchItem>
        {optionsIds.map(id => (
          <Option key={id} parameterId={parameterId} optionId={id} />
        ))}
      </Stack>
    )
  )
})

const Option = hoc.provideOption(
  ({
    index,
    name,
    suggestedBy,
    optionId,
    onRenameOption,
    onRemoveOption,
    onUploadFiles,
  }) => {
    const modal = useContext(modalContext)

    return (
      <UploadDialog multiple onSelect={onUploadFiles}>
        {openDialog => (
          <>
            <ContextMenu
              items={[
                {
                  title: "Delete option",
                  icon: bin,
                  onClick: () =>
                    modal.open(
                      <ConfirmBox
                        title="Delete this option and its attachments?"
                        confirmTitle="Delete"
                        cancelTitle="Cancel"
                        onCancel={modal.close}
                        onConfirmSuccess={modal.close}
                        onConfirm={onRemoveOption}
                      />
                    ),
                },
                {
                  title: "Attach files",
                  icon: attachment,
                  onClick: openDialog,
                },
              ]}
            >
              <LabeledInput
                index={index}
                text={name}
                onSaveBlur={value => onRenameOption(value)}
              />
            </ContextMenu>
            <FilesList optionId={optionId} />
          </>
        )}
      </UploadDialog>
    )
  }
)
