import React from "react"
import CellHead from "../CellHead"
import CellBox from "../CellBox"
import CellWrap from "../CellWrap"

export default ({
  title,
  leftLink,
  onSaveBlur,
  isEditable,
  children,
  contextMenu,
  postfix,
  border,
}) => (
  <CellWrap>
    <CellHead
      title={title}
      leftLink={leftLink}
      postfix={postfix}
      isEditable={isEditable}
      contextMenu={contextMenu}
      onSaveBlur={onSaveBlur}
      border={border}
    />
    <CellBox border={border}>{children}</CellBox>
  </CellWrap>
)
