const { REACT_APP_BROADCAST_HOST } = process.env

export default function connect(conversationId, callback) {
  let closedByUser = false
  const ws = new WebSocket(
    `${REACT_APP_BROADCAST_HOST}/conversations/${conversationId}`
  )

  ws.onopen = () => {
    console.log("opened", conversationId)
  }

  ws.onmessage = e => {
    const event = JSON.parse(e.data)

    callback(event)
  }

  ws.onerror = e => {
    console.log(e.message)
  }

  ws.onclose = e => {
    console.log("closed")

    if (!closedByUser) {
      setTimeout(
        () =>
          connect(
            conversationId,
            callback
          ),
        1000
      )
    }
  }

  return () => {
    closedByUser = true
    ws.close()
  }
}
