import React, { useContext } from "react"
import { parse } from "query-string"
import { Formik, Form } from "formik"
import {
  Layout,
  Row,
  GoogleAuth,
  Box,
  BottomLink,
} from "components/common/user"
import { Button, Input } from "components/kit"
import { Field } from "components/service"
import { context as userContext } from "context/user"
import * as userApi from "api/user"
import * as paths from "paths.js"
import * as data from "./data"

export default () => {
  const user = useContext(userContext)
  const { email } = parse(document.location.search)

  async function submitForm(formData, { setFieldError, setSubmitting }) {
    try {
      user.setParameters({ redirectWithQuery: true })
      user.createSession(await userApi.signUp(formData))
    } catch (err) {
      if (err.response && err.response.data.type === "email_already_exists") {
        setFieldError("email", "This email already exists")
        setSubmitting(false)
        return
      }

      throw err
    }
  }

  return (
    <Layout title="Sign up">
      <Box>
        <Formik
          validationSchema={data.validationSchema}
          initialValues={{
            ...data.initialValues,
            email: email || data.initialValues.email,
          }}
          onSubmit={submitForm}
        >
          {({ isSubmitting }) => (
            <Form>
              <Row>
                <Field
                  type="text"
                  name="email"
                  placeholder="Email"
                  component={Input}
                />
              </Row>
              <Row>
                <Field
                  type="password"
                  name="password"
                  placeholder="Password"
                  component={Input}
                />
              </Row>

              <Button full disabled={isSubmitting} type="submit">
                Sign Up
              </Button>
              <GoogleAuth>Sign Up with Google</GoogleAuth>
            </Form>
          )}
        </Formik>
        <BottomLink to={paths.signIn}>Already have an account?</BottomLink>
      </Box>
      <div className="text-midgray max-w-sm w-full text-center mt-12 px-5">
        By signing up I expressly agree to XXX Terms of use and Privacy policy
      </div>
    </Layout>
  )
}
