import { schema } from "normalizr"

export const conversationSchema = new schema.Entity("conversations")
export const responseSchema = new schema.Entity("responses")
export const offerSchema = new schema.Entity("offers")
export const supplierSchema = new schema.Entity("suppliers", {
  offers: [offerSchema],
  responses: [responseSchema],
  conversation: conversationSchema,
  parameters_conversations: [conversationSchema],
})
export const fileSchema = new schema.Entity("files")
export const optionSchema = new schema.Entity("options", {
  files: [fileSchema],
})
export const parameterSchema = new schema.Entity("parameters", {
  options: [optionSchema],
  conversation: conversationSchema,
})
export const invitationSchema = new schema.Entity("invitations")
