import React from "react"
import { Column } from "components/common/order"

export default () => {
  const className = "h-40 border-r border-lines"

  return (
    <div className="flex">
      <Column className={className} />
      <Column className={className} />
      <Column className={className} />
      <Column />
    </div>
  )
}
