import { connect } from "hoc/index"
import * as orderSelectors from "modules/supplier/selectors/order"
import * as clientSelectors from "modules/supplier/selectors/client"

export const provideRoot = connect({
  orderName: orderSelectors.getName,
})

export const provideClient = connect({
  name: clientSelectors.getName,
  company: clientSelectors.getCompany,
})
