import React from "react"
import placeholder from "assets/avatar-placeholder.png"

export default ({ image }) => (
  <img
    className="w-12 h-12 rounded-full border-2 border-moist"
    src={image || placeholder}
    alt=""
  />
)
