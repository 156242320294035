import * as yup from "yup"

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Wrong email format")
    .required("Please enter email"),
  password: yup.string().required("Please enter password"),
})

export const initialValues = {
  email: "",
  password: "",
}
