import React from "react"

export default ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="w-2 h-2 rounded-full bg-bright-blue cursor-pointer"
    />
  )
}
