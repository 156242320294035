import React from "react"
import cx from "classnames"

const columnWidth = "calc((100vw - 6rem) / 4)"

export default ({ children, width = columnWidth, ...props }) => (
  <div {...props} style={{ width, flexShrink: 0 }}>
    {children}
  </div>
)
