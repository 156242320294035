import * as constants from "./constants"

export const orderRenamed = name => ({
  type: constants.ORDER_RENAMED,
  payload: { name },
})

export const parameterRenamed = (parameterId, name) => ({
  type: constants.PARAMETER_RENAMED,
  payload: {
    parameterId,
    name,
  },
})

export const parameterAccepted = (parameter, conversationIds) => ({
  type: constants.PARAMETER_ACCEPTED,
  payload: {
    parameter,
    conversationIds,
  },
})

export const optionRenamed = (optionId, name) => ({
  type: constants.OPTION_RENAMED,
  payload: {
    optionId,
    name,
  },
})

export const optionCreated = (parameterId, option) => ({
  type: constants.OPTION_CREATED,
  payload: {
    parameterId,
    option,
  },
})

export const optionAccepted = optionId => ({
  type: constants.OPTION_ACCEPTED,
  payload: {
    optionId,
  },
})

export const parameterCreated = (parameter, conversationIds) => ({
  type: constants.PARAMETER_CREATED,
  payload: { parameter, conversationIds },
})

export const optionRemoved = (parameterId, optionId) => ({
  type: constants.OPTION_REMOVED,
  payload: { parameterId, optionId },
})

export const optionFileRemoved = (optionId, fileId) => ({
  type: constants.OPTION_FILE_REMOVED,
  payload: { optionId, fileId },
})

export const parameterRemoved = parameterId => ({
  type: constants.PARAMETER_REMOVED,
  payload: { parameterId },
})

export const supplierRemoved = supplierId => ({
  type: constants.SUPPLIER_REMOVED,
  payload: { supplierId },
})

export const invitationRemoved = invitationId => ({
  type: constants.INVITATION_REMOVED,
  payload: { invitationId },
})

export const invitationCreated = invitation => ({
  type: constants.INVITATION_CREATED,
  payload: { invitation },
})

export const selectOffer = (supplierId, offerId) => ({
  type: constants.SELECT_OFFER,
  payload: { supplierId, offerId },
})

export const requestOptionFileUpload = (optionId, tempFileId, fileName) => ({
  type: constants.OPTION_FILE_UPLOAD_REQUEST,
  payload: {
    optionId,
    tempFileId,
    fileName,
  },
})

export const successOptionFileUpload = (optionId, tempFileId, file) => ({
  type: constants.OPTION_FILE_UPLOAD_SUCCESS,
  payload: {
    optionId,
    tempFileId,
    file,
  },
})

export const parameterConversationCreated = (
  parameterId,
  supplierId,
  conversationId
) => ({
  type: constants.PARAMETER_CONVERSATION_CREATED,
  payload: { parameterId, supplierId, conversationId },
})

export const newUnreadMessage = conversationId => ({
  type: constants.NEW_UNREAD_MESSAGE,
  payload: { conversationId },
})

export const updateRemoved = updateId => ({
  type: constants.UPDATE_REMOVED,
  payload: { updateId },
})
