import React from "react"
import attachment from "assets/attachment.svg"
import circleCross from "assets/circle-cross.svg"

export default ({ name, url, deletable, onRemoveClick }) => {
  return (
    <div className="flex items-center group">
      <a
        href={url}
        download={name}
        className="text-darkest flex items-center hover:underline"
      >
        <img src={attachment} alt="" className="w-3 mr-2" />
        {name}
      </a>
      {deletable && (
        <button
          onClick={onRemoveClick}
          className="ml-2 invisible group-hover:visible"
        >
          <img src={circleCross} alt="" className="w-5" />
        </button>
      )}
    </div>
  )
}
