import React, { useState, useRef } from "react"
import * as R from "ramda"
import cx from "classnames"
import TextareaAutosize from "react-autosize-textarea"
import { Spinner } from "components/kit"

export default ({
  fontSize = "base",
  fontWeight = "normal",
  textColor = "black",
  spinnerOffset = "4",
  value,
  defaultValue = "",
  name,
  placeholder,
  xSpace,
  ySpace,
  hoverBg,
  focusBg,
  bg,
  spinnerColor,
  postfix,
  isEditable,
  autoFocus,
  shouldUpdate = R.T,
  style,
  onChange = R.identity,
  // TODO: rename to `onSave`?
  onSaveBlur = R.identity,
  onSaveBlurSuccess = R.identity,
  onCancel = R.identity,
}) => {
  const [text, setText] = useState(defaultValue)
  const [isSaving, setSaving] = useState(false)
  const space = `py-${ySpace} -my-${ySpace} px-${xSpace} -mx-${xSpace}`
  const ref = useRef()

  return (
    <div className="flex items-center" style={style}>
      <div className="relative w-full">
        <div
          className={cx(
            "invisible whitespace-pre-wrap word-break",
            `font-${fontWeight} text-${fontSize} ${space}`
          )}
        >
          {value || text || placeholder || "|"}
        </div>
        <TextareaAutosize
          ref={ref}
          className={cx(
            "absolute top-0 left-0 w-full focus:outline-none resize-none",
            `font-${fontWeight} text-${fontSize} text-${textColor} ${space}`,
            isEditable
              ? cx(
                  `hover:bg-${hoverBg} focus:bg-${focusBg} rounded`,
                  !bg ? "bg-transparent " : `bg-${bg}`
                )
              : "cursor-text bg-transparent"
          )}
          autoFocus={autoFocus}
          placeholder={placeholder}
          style={{ boxSizing: "content-box" }}
          disabled={!isEditable}
          name={name}
          value={value || text}
          onKeyDown={e => {
            if (e.key === "Enter") {
              e.preventDefault()
              ref.current.blur()
            }
          }}
          onChange={({ target }) => {
            if (!shouldUpdate(target.value)) {
              return
            }

            typeof value === "undefined"
              ? setText(target.value)
              : onChange({
                  target: {
                    name,
                    value: target.value,
                  },
                })
          }}
          onBlur={async ({ target }) => {
            if (target.value !== defaultValue) {
              setSaving(true)
              await onSaveBlur(target.value)
              setSaving(false)
              onSaveBlurSuccess()
            } else {
              onCancel()
            }
          }}
        />
      </div>
      {isSaving && (
        <div className={`ml-${spinnerOffset}`}>
          <Spinner />
        </div>
      )}
      {postfix && <span className="ml-3 text-xs">{postfix}</span>}
    </div>
  )
}
