import React from "react"
import cx from "classnames"

export default ({ error, background = "atomic", ...props }) => (
  <>
    <input
      className={cx(
        "appearance-none font-bold border rounded w-full h-12 px-5 leading-tight focus:outline-none",
        "border-paper text-darkest focus:shadow-outline",
        `bg-${background}`
      )}
      {...props}
    />
    {error && <p className="text-red-600 text-xs mt-2">{error}</p>}
  </>
)
