import { useState } from "react"

export default (initialState, key) => {
  const rawStateFromStorage = localStorage.getItem(key)
  const stateFromStorage =
    rawStateFromStorage && JSON.parse(rawStateFromStorage)

  const noResult =
    typeof stateFromStorage === "undefined" || stateFromStorage === null

  const [value, setter] = useState(noResult ? initialState : stateFromStorage)

  return [
    value,
    data => {
      localStorage.setItem(key, JSON.stringify(data))
      setter(data)
    },
  ]
}
