import { connect } from "hoc/index"
import * as reactRedux from "react-redux"
import * as parameterApi from "api/parameter"
import * as optionApi from "api/option"
import * as selectors from "modules/buyer/selectors"
import * as actions from "modules/buyer/actions"
import * as updateApi from "api/update"

export const provideRoot = connect(
  {
    parametersIds: selectors.getParametersIds,
  },
  (dispatch, { orderId }) => ({
    onCreateParameter: async name => {
      const { parameter, conversation_ids } = await parameterApi.createEntry(
        orderId,
        name
      )
      dispatch(actions.parameterCreated(parameter, conversation_ids))
    },
  })
)

export const provideHead = connect(
  {
    parameterName: selectors.getParameterName,
    hasSuppliers: selectors.hasSuppliers,
    suggestedBy: selectors.getParameterSuggestedBy,
  },
  dispatch => ({
    onRenameParameter: async (parameterId, name) => {
      const parameter = await parameterApi.updateEntry(parameterId, name)
      dispatch(actions.parameterRenamed(parameterId, parameter.name))
    },
    onRemoveParameter: async parameterId => {
      await parameterApi.removeEntry(parameterId)
      dispatch(actions.parameterRemoved(parameterId))
    },
  })
)

export const provideTail = connect(
  {
    suppliersIds: selectors.getSuppliersIds,
    suggestedBy: selectors.getParameterSuggestedBy,
    updates: selectors.getParameterUpdates,
  },
  dispatch => ({
    onRemoveUpdates: async updates => {
      for (let update of updates) {
        dispatch(actions.updateRemoved(update.id))
      }
      await Promise.all(updates.map(update => updateApi.remove(update.id)))
    },
  })
)

export const provideSuggested = connect(
  {
    parameterName: selectors.getParameterName,
    optionsIds: selectors.getOptionsIds,
  },
  dispatch => ({
    onRemoveParameter: async parameterId => {
      await parameterApi.removeEntry(parameterId)
      dispatch(actions.parameterRemoved(parameterId))
    },
    onAcceptParameter: async (parameterId, optionsIds) => {
      const {
        parameter,
        conversation_ids,
      } = await parameterApi.acceptSuggested(parameterId)

      if (optionsIds) {
        for (let id of optionsIds) {
          dispatch(actions.optionAccepted(id))
        }
      }

      dispatch(actions.parameterAccepted(parameter, conversation_ids))
    },
  })
)

export const provideSupplier = connect(
  {
    conversationId: selectors.getParameterConversationId,
  },
  dispatch => ({
    onConversationCreated: (parameterId, supplierId, conversationId) => {
      dispatch(
        actions.parameterConversationCreated(
          parameterId,
          supplierId,
          conversationId
        )
      )
    },
    onNewMessage: (event, userId) => {
      const sound = new Audio("/new-message.mp3")

      if (
        event.name !== "new_message" ||
        userId === event.payload.message.sender_id
      )
        return

      sound.play()
      dispatch(actions.newUnreadMessage(event.payload.message.conversation_id))
    },
  })
)

export const provideResponseLine = reactRedux.connect(
  (state, { conversationId }) => ({
    unreadMessages:
      conversationId &&
      selectors.getConversationUnreadMessages(state, {
        conversationId,
      }),
  })
)

const getReferences = (state, { supplierId }) => {
  const offerIds = state.entities.suppliers[supplierId].offers

  return {
    offers:
      offerIds &&
      offerIds.map(offerId => {
        return state.entities.offers[offerId]
      }),
    parameters: state.entry.parameters
      .filter(parameterId => state.entities.parameters[parameterId].options)
      .map(parameterId => {
        const parameter = state.entities.parameters[parameterId]

        return {
          name: parameter.name,
          options: parameter.options.map(
            optionId => state.entities.options[optionId]
          ),
        }
      }),
  }
}

export const provideQuickReference = connect({
  items: getReferences,
  title: selectors.getParameterName,
})

export const provideOptionCreation = connect(
  {
    topOffset: selectors.getOptionsIds,
    index: selectors.getNextOptionIndex,
  },
  dispatch => ({
    onCreateOption: async (parameterId, name) => {
      const option = await optionApi.createEntry(parameterId, name)
      dispatch(actions.optionCreated(parameterId, option))
    },
  })
)
