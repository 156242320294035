import { request } from "utils/index"

export const fetchMessages = (conversationId, limit, offset) =>
  request({
    url: `/conversations/${conversationId}/messages?limit=${limit}&offset=${offset}`,
    method: "GET",
  })

export const sendMessage = (
  conversationId,
  text,
  attachmentIds,
  replyTo,
  refOptionId,
  refOfferId
) =>
  request({
    url: `/conversations/${conversationId}/messages`,
    method: "POST",
    data: {
      text,
      attachment_ids: attachmentIds,
      reply_to: replyTo,
      ref_option_id: refOptionId,
      ref_offer_id: refOfferId,
    },
  })

export const removeMessage = messageId =>
  request({
    url: `/messages/${messageId}`,
    method: "DELETE",
  })

export const readMessages = messageIds =>
  request({
    url: `/messages/read`,
    method: "POST",
    data: {
      message_ids: messageIds,
    },
  })
