import React, { memo } from "react"
import LabeledInput from "../LabeledInput"
import { ContextMenu, Stack } from "components/kit"
import { When } from "components/service"

// Philosophy - do one thing and do it great. Split as much UI parts as possible
export default memo(({ items, createContextMenu, onSaveItem }) => (
  <Stack direction="col" spacing="3" stretchItem>
    {items.map((item, i) => (
      <When
        key={i}
        cond={createContextMenu}
        component={ContextMenu}
        items={createContextMenu && createContextMenu(item.id)}
      >
        <LabeledInput
          index={item.index}
          text={item.name}
          onSaveBlur={value => onSaveItem(item.id, value)}
        />
      </When>
    ))}
  </Stack>
))
