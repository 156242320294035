import { connect } from "hoc/index"
import * as actions from "modules/supplier/actions"
import * as reactRedux from "react-redux"
import * as orderSelectors from "modules/supplier/selectors/order"
import * as clientSelectors from "modules/supplier/selectors/client"
import * as conversationSelectors from "modules/supplier/selectors/conversation"

export const provideRoot = connect({
  role: orderSelectors.getRole,
})

export const provideClient = connect(
  {
    orderId: orderSelectors.getId,
    name: clientSelectors.getName,
    company: clientSelectors.getCompany,
    buyerId: clientSelectors.getId,
    conversationId: orderSelectors.getConversationId,
  },
  dispatch => ({
    onNewMessage: (event, userId) => {
      const sound = new Audio("/new-message.mp3")

      if (
        event.name !== "new_message" ||
        userId === event.payload.message.sender_id
      )
        return

      sound.play()
      dispatch(actions.newUnreadMessage(event.payload.message.conversation_id))
    },
  })
)

export const provideDiscussLink = reactRedux.connect(
  (state, { conversationId }) => ({
    unreadMessages:
      conversationId &&
      conversationSelectors.getUnreadMessages(state, { conversationId }),
  })
)
