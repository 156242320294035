import { call, put, select, takeEvery } from "redux-saga/effects"
import * as offerApi from "api/offer"
import * as offerSelectors from "./selectors/offer"
import * as actions from "./actions"
import * as constants from "./constants"

const createOffer = function*(action) {
  const responseIds = yield select(offerSelectors.getCurrentResponseIds)
  const offer = yield call(offerApi.create, action.payload.price, responseIds)
  yield put(actions.successOfferCreation(offer))
  yield put(actions.closeCurrentOffer())
}

const updateOffer = function*(action) {
  const responseIds = yield select(offerSelectors.getCurrentResponseIds)
  const offer = yield call(
    offerApi.update,
    action.payload.offerId,
    action.payload.price,
    responseIds
  )
  yield put(actions.successOfferUpdate(offer))
  yield put(actions.closeCurrentOffer())
}

export default function*() {
  yield takeEvery(constants.CREATE_OFFER_REQUEST, createOffer)
  yield takeEvery(constants.UPDATE_OFFER_REQUEST, updateOffer)
}
