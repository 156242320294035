import React, { useState } from "react"
import { Button, Stack } from "components/kit"
import * as orderApi from "api/order"
import normalize from "modules/supplier/schemas"
import * as hoc from "./hoc"

export default hoc.provideRoot(({ children, orderId, onOrderLoaded }) => {
  const [isAccepting, setAccepting] = useState(false)
  const [isDeclining, setDeclining] = useState(false)

  const accept = async () => {
    setAccepting(true)
    await orderApi.accept(orderId)
    const order = await orderApi.fetchEntry(orderId)
    onOrderLoaded(normalize(order))
  }
  const decline = async () => {
    setDeclining(true)
  }

  return (
    <div className="flex">
      {children}
      <div className="bg-white max-w-2xl w-full pt-16 pb-12 px-6 flex flex-col items-center text-darkest text-center">
        <p className="mb-12">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        <Stack direction="row" spacing="3">
          <Button
            onClick={accept}
            disabled={isDeclining}
            isSpinning={isAccepting}
          >
            Accept project
          </Button>
          <Button
            onClick={decline}
            disabled={isAccepting}
            isSpinning={isDeclining}
            theme="secondary"
          >
            Decline project
          </Button>
        </Stack>
      </div>
    </div>
  )
})
