import * as R from "ramda"
import { createSelector } from "reselect"
import * as responseSelectors from "./response"

const empty = []

export const getIds = state => state.entry.offers || empty

export const getPrice = (state, { offerId }) =>
  state.entities.offers[offerId].price

export const isEditing = (state, { offerId }) =>
  state.current_offer.is_visible &&
  state.current_offer.type === "editing" &&
  state.current_offer.offer_id === offerId

export const isCreationActive = state =>
  state.current_offer.is_visible && state.current_offer.type === "creation"

export const isFormMode = state => state.current_offer.is_visible

export const getEditingOfferResponses = state => {
  const editingOffer = state.entities.offers[state.current_offer.offer_id]
  return (
    editingOffer &&
    editingOffer.selections &&
    editingOffer.selections.reduce((acc, { response_id }) => {
      const optionId = state.entities.responses[response_id].option_id
      const parameterId = state.entry.parameters.find(parameterId => {
        const { options } = state.entities.parameters[parameterId]

        return options && options.includes(optionId)
      })

      return {
        ...acc,
        [parameterId]: response_id,
      }
    }, {})
  )
}

export const getCurrentResponses = createSelector(
  [
    R.path(["current_offer", "type"]),
    R.path(["current_offer", "responses"]),
    getEditingOfferResponses,
  ],
  (type, selectedIds, editingResponses) =>
    type === "creation"
      ? selectedIds
      : type === "editing" && R.merge(editingResponses, selectedIds)
)

export const getCurrentResponseIds = createSelector(
  [getCurrentResponses],
  R.values
)

export const isCompleted = createSelector(
  [state => state.entities.parameters, getCurrentResponseIds],
  (parameters, responseIds) =>
    R.values(parameters).filter(R.prop("options")).length === responseIds.length
)

export const isTouched = createSelector(
  [R.path(["current_offer", "responses"]), getEditingOfferResponses],
  (selectedIds, editingResponses) =>
    !R.equals(R.merge(editingResponses, selectedIds), editingResponses)
)

export const isTouchedCompleted = createSelector(
  [isTouched, isCompleted],
  R.and
)

export const isResponseSelected = createSelector(
  [getCurrentResponseIds, responseSelectors.getId],
  (ids, id) => ids && ids.includes(id)
)
