import { request } from "utils/index"

export const fetchList = (limit, offset) =>
  request({
    url: `/me/invitations?limit=${limit}&offset=${offset}`,
    method: "GET",
  })

export const removeEntry = invitationId =>
  request({
    url: `/invitations/${invitationId}`,
    method: "DELETE",
  })

export const createEntry = (orderId, { email, name, message }) =>
  request({
    url: `/orders/${orderId}/invitations`,
    method: "POST",
    data: {
      email,
      name,
      message,
    },
  })
