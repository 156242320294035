const empty = []

export const getIds = state =>
  (state.entry.parameters &&
    [...state.entry.parameters].sort((a, b) => {
      const aUpdates = getUpdates(state, { parameterId: a })
      const bUpdates = getUpdates(state, { parameterId: b })
      const hasAUpdates = aUpdates && !!aUpdates.length
      const hasBUpdates = bUpdates && !!bUpdates.length

      if (hasAUpdates && !hasBUpdates) return -1
      if (!hasAUpdates && hasBUpdates) return 1
    })) ||
  empty

export const getName = (state, { parameterId }) =>
  state.entities.parameters[parameterId].name

export const getUpdates = (state, { parameterId }) =>
  state.entry.updates &&
  state.entry.updates.filter(
    update => update.type === "parameter" && update.entry_id === parameterId
  )

export const getSuggestedBy = (state, { parameterId }) =>
  state.entities.parameters[parameterId].suggested_by

export const getConversationId = (state, { parameterId }) =>
  state.entities.parameters[parameterId].conversation

export const getNextOptionIndex = (state, { parameterId }) =>
  state.entities.parameters[parameterId].options
    ? state.entities.parameters[parameterId].options.length + 1
    : 1
