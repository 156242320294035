import * as paths from "paths.js"

export const redirectTo = (isAuthenticated, userData, user) => {
  if (!isAuthenticated) {
    return link(paths.signIn, user)
  }

  if (!userData.profile) {
    return link(paths.profileSetup, user)
  }

  return link(paths.orders, user)
}

const link = (url, user) =>
  user.redirectWithQuery
    ? `${url}${document.location.search}`
    : `${url}?redirect_to=${document.location.pathname}`
