import React, { createContext, useRef } from "react"
import NotificationSystem from "react-notification-system"

export const context = createContext()

export const Provider = ({ children }) => {
  const notificationSystem = useRef()

  return (
    <context.Provider
      value={{
        show: (message, level = "success") => {
          notificationSystem.current.addNotification({
            message,
            level,
            position: "tc",
          })
        },
      }}
    >
      {children}
      <NotificationSystem ref={notificationSystem} />
    </context.Provider>
  )
}
