import React, { useContext, forwardRef } from "react"
import { parse } from "query-string"
import { Formik, Form } from "formik"
import { Layout, Row, Box } from "components/common/user"
import { Button, Input, PhotoUpload } from "components/kit"
import { Field } from "components/service"
import { context as userContext } from "context/user"
import { context as modalContext } from "context/modal"
import * as userApi from "api/user"
import * as data from "./data"

export default () => {
  const user = useContext(userContext)
  const modal = useContext(modalContext)

  async function submitForm(formData) {
    const { redirect_to } = parse(document.location.search)
    const profile = await userApi.setupProfile(formData)

    user.setParameters({ forceRedirect: redirect_to })
    user.setData({ ...user.data, profile })

    !redirect_to && modal.open(<Greeting onClose={modal.close} />)
  }

  return (
    <Layout title="Profile setup">
      <Box>
        <Formik
          validationSchema={data.validationSchema}
          initialValues={data.initialValues}
          onSubmit={submitForm}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-12">
                <Field name="avatar" component={PhotoUpload} />
              </div>
              <Row>
                <Field
                  name="name"
                  type="text"
                  placeholder="Name"
                  component={Input}
                />
              </Row>
              <Row>
                <Field
                  name="company"
                  type="text"
                  placeholder="Company"
                  component={Input}
                />
              </Row>

              <Button full disabled={isSubmitting} type="submit">
                Start using the app
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Layout>
  )
}

const P = ({ children }) => <p className="mb-4">{children}</p>

const Greeting = forwardRef(({ onClose }, ref) => {
  return (
    <div
      ref={ref}
      className="bg-white pane-shadow text-darkest max-w-4xl w-full"
    >
      <p className="bg-lightest py-12 px-24 leading-none font-bold text-2xl">
        Welcome to Specful Alpha
      </p>

      <div className="px-24 py-12">
        <P>We are really excited to have you on here!</P>

        <P>
          One important thing to note before you start: while the app is ready
          for a test-drive, at the moment it only has the most basic features
          turned on. You may come across buttons that are just placeholders and
          aren't functional.
        </P>

        <P>
          If something crashes, please let us know what happened so that we
          could fix it.
        </P>

        <P>
          For all bugs, ideas, and feature requests please shoot a quick email
          at&nbsp;
          <a href="mailto:neil@specful.com">neil@logictaps.com</a>
        </P>

        <P>Thank you for trying Specful,</P>

        <P>Neil</P>
      </div>

      <div className="flex justify-end px-24 pb-12">
        <Button type="button" onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  )
})
