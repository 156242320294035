import { stringify } from "query-string"

const { REACT_APP_GOOGLE_CLIENT_ID } = process.env

export const redirectToConsentScreen = state =>
  (document.location.href = makeSignInLink(state))

export const makeSignInLink = state =>
  `https://accounts.google.com/o/oauth2/v2/auth?${stringify({
    scope: "email",
    response_type: "code",
    state: state && JSON.stringify(state),
    client_id: REACT_APP_GOOGLE_CLIENT_ID,
    redirect_uri: document.location.origin + "/google-callback",
  })}`
