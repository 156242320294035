import * as constants from "./constants"

export const openCreation = () => ({
  type: constants.OPEN_CREATION,
})

export const openEdition = offerId => ({
  type: constants.OPEN_EDITION,
  payload: {
    offerId,
  },
})

export const closeCurrentOffer = () => ({
  type: constants.CLOSE_CURRENT_OFFER,
})

export const selectResponse = (parameterId, responseId) => ({
  type: constants.SELECT_RESPONSE,
  payload: {
    parameterId,
    responseId,
  },
})

export const requestOfferCreation = price => ({
  type: constants.CREATE_OFFER_REQUEST,
  payload: {
    price,
  },
})

export const successOfferCreation = offer => ({
  type: constants.CREATE_OFFER_SUCCESS,
  payload: {
    offer,
  },
})

export const requestOfferUpdate = (offerId, price) => ({
  type: constants.UPDATE_OFFER_REQUEST,
  payload: {
    offerId,
    price,
  },
})

export const successOfferUpdate = offer => ({
  type: constants.UPDATE_OFFER_SUCCESS,
  payload: {
    offer,
  },
})

export const successOfferRemove = offerId => ({
  type: constants.REMOVE_OFFER_SUCCESS,
  payload: {
    offerId,
  },
})

export const successRespond = (optionId, response) => ({
  type: constants.RESPOND_SUCCESS,
  payload: {
    optionId,
    response,
  },
})

export const loadOrder = (order, merge = false) => ({
  type: constants.LOAD_ORDER,
  payload: {
    order,
    merge,
  },
})

export const parameterConversationCreated = (parameterId, conversationId) => ({
  type: constants.PARAMETER_CONVERSATION_CREATED,
  payload: { parameterId, conversationId },
})

export const parameterSuggested = parameter => ({
  type: constants.PARAMETER_SUGGESTED,
  payload: { parameter },
})

export const parameterRemoved = parameterId => ({
  type: constants.PARAMETER_REMOVED,
  payload: { parameterId },
})

export const parameterRenamed = (parameterId, name) => ({
  type: constants.PARAMETER_RENAMED,
  payload: { parameterId, name },
})

export const newUnreadMessage = conversationId => ({
  type: constants.NEW_UNREAD_MESSAGE,
  payload: { conversationId },
})

export const optionSuggested = (parameterId, option) => ({
  type: constants.OPTION_SUGGESTED,
  payload: { parameterId, option },
})

export const optionRemoved = (parameterId, optionId) => ({
  type: constants.OPTION_REMOVED,
  payload: { parameterId, optionId },
})

export const optionRenamed = (optionId, name) => ({
  type: constants.OPTION_RENAMED,
  payload: { optionId, name },
})

export const updateRemoved = updateId => ({
  type: constants.UPDATE_REMOVED,
  payload: { updateId },
})
