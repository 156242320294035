import React, { useEffect, useContext, useState, useCallback } from "react"
import { Layout } from "components/common/app"
import {
  Container,
  Cell,
  Column,
  DiscussLink,
  Chat,
} from "components/common/order"
import { When } from "components/service"
import Provider from "modules/supplier/Provider"
import { context as conversationsOnlineContext } from "context/conversations-online"
import { context as userContext } from "context/user"
import * as orderApi from "api/order"
import Header from "./Header"
import Parameters from "./Parameters"
import Offers from "./Offers"
import Invitation from "./Invitation"
import * as hoc from "./hoc"

export default ({ order }) => {
  return (
    <Provider order={order}>
      <Root order={order} />
    </Provider>
  )
}

const Root = hoc.provideRoot(({ order, role, onNewMessage }) => {
  return (
    <Layout>
      <Header />
      <Container>
        <When cond={role === "invitee"} component={Invitation}>
          <ClientInfo />
          <Offers />
          <Parameters role={role} orderId={order.id} />
        </When>
      </Container>
    </Layout>
  )
})

const ClientInfo = hoc.provideClient(
  ({ onNewMessage, conversationId, orderId, buyerId, name, company }) => {
    const user = useContext(userContext)
    const conversationsUpdate = useContext(conversationsOnlineContext)
    const [reference, setReference] = useState()
    const createConversation = useCallback(
      async () =>
        (await orderApi.createConversation(orderId, buyerId)).conversation_id,
      []
    )

    useEffect(() => {
      if (conversationId) {
        conversationsUpdate.connect(conversationId)
        conversationsUpdate.subscribe(conversationId, event =>
          onNewMessage(event, user.data.id)
        )
      }
    }, [conversationId])

    return (
      <Chat
        reference={reference}
        conversationId={conversationId}
        createConversation={createConversation}
        onRemoveReference={() => setReference(void 0)}
        onClose={() => setReference(void 0)}
      >
        {({ open }) => (
          <Column>
            <Cell
              leftLink={
                <ConnectedDiscussLink
                  title="Chat"
                  conversationId={conversationId}
                  onClick={open}
                />
              }
            >
              <div className="flex flex-col p-6 -m-6">
                <span className="font-bold">{name}</span>
                <span>{company}</span>
              </div>
            </Cell>
          </Column>
        )}
      </Chat>
    )
  }
)

const ConnectedDiscussLink = hoc.provideDiscussLink(DiscussLink)
