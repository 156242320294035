import React, {
  forwardRef,
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react"
import { context as modalContext } from "context/modal"
import { context as conversationsOnlineContext } from "context/conversations-online"
import ReactDOM from "react-dom"
import * as R from "ramda"
import { Form, Formik, Field } from "formik"
import moment from "moment"
import cx from "classnames"
import { context as userContext } from "context/user"
import { useClickOutside } from "hooks/index"
import {
  Spinner,
  Stack,
  UploadDialog,
  ContextMenu,
  ConfirmBox,
} from "components/kit"
import { IndexedLabel, Label } from "components/common/order"
import { Scrollbar } from "components/service"
import planeIcon from "assets/plane.svg"
import attachmentIcon from "assets/attachment.svg"
import crossIcon from "assets/circle-cross.svg"
import * as hooks from "./hooks"

const modalNode = document.getElementById("modal")

export default ({
  sidebar,
  children,
  reference,
  conversationId,
  createConversation,
  onRemoveReference,
  onClose,
}) => {
  const [isVisible, setVisible] = useState(false)
  const open = useCallback(() => setVisible(true), [])
  const close = useCallback(() => {
    setVisible(false)
    onClose && onClose()
  }, [])

  return (
    <>
      {children({ open, close })}
      {isVisible &&
        ReactDOM.createPortal(
          <Body
            conversationId={conversationId}
            reference={reference}
            createConversation={createConversation}
            sidebar={sidebar}
            onClose={close}
            onRemoveReference={onRemoveReference}
          />,
          modalNode
        )}
    </>
  )
}

const Body = ({
  sidebar,
  reference,
  conversationId,
  createConversation,
  onClose,
  onRemoveReference,
}) => {
  const user = useContext(userContext)
  const messages = hooks.useMessages(conversationId, createConversation)
  const { subscribe } = useContext(conversationsOnlineContext)
  const messagesScroll = useRef()
  const [reply, setReply] = useState()
  const scrollBottom = useCallback(
    () =>
      messagesScroll.current.scrollTop(
        messagesScroll.current.getScrollHeight()
      ),
    []
  )

  useEffect(
    () =>
      subscribe(conversationId, event => {
        if (
          event.name !== "new_message" ||
          user.data.id === event.payload.message.sender_id
        )
          return

        messages.append(event.payload.message)
        scrollBottom()
      }),
    [conversationId]
  )

  return (
    <div
      className="fixed top-0 left-0 w-full h-full flex"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
      onClick={onClose}
    >
      <div
        onClick={e => {
          e.stopPropagation()
        }}
        className={cx(
          "w-full h-full bg-lighter ml-auto z-30 flex",
          sidebar ? "max-w-5xl" : "max-w-3xl"
        )}
      >
        {sidebar && <div className="h-full">{sidebar}</div>}
        <div className="flex flex-col w-full">
          {(!messages.items || messages.isLoading) && (
            <div className="flex w-full justify-center mt-6">
              <Spinner />
            </div>
          )}
          {messages.items && (
            <MessagesList
              ref={messagesScroll}
              offset={messages.offset}
              items={messages.items}
              read={messages.read}
              onLoadMore={messages.lazyLoad}
              onRemove={messages.remove}
              onReply={setReply}
            />
          )}
          <div className="p-4 flex-shrink-0 mt-auto">
            <Typing
              reply={reply}
              reference={reference}
              onRemoveReply={() => setReply(void 0)}
              onRemoveReference={onRemoveReference}
              onSubmit={messages.send}
              onSubmitSuccess={scrollBottom}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const MessagesList = forwardRef(
  ({ offset, items, read, onLoadMore, onRemove, onReply }, ref) => {
    const user = useContext(userContext)
    const onScrollFrame = async ({ scrollTop, scrollHeight }) => {
      if (scrollTop === 0) {
        await onLoadMore()

        if (ref.current) {
          ref.current.scrollTop(ref.current.getScrollHeight() - scrollHeight)
        }
      }
    }

    useEffect(() => {
      const instance = ref.current
      const scrollHeight = instance.getScrollHeight()
      const clientHeight = instance.getClientHeight()

      const scrollBottom = () => instance.scrollTop(instance.getScrollHeight())

      scrollBottom()

      if (scrollHeight <= clientHeight + 50) {
        onLoadMore().then(scrollBottom)
      }
    }, [])

    // send read when message is in viewport?
    useEffect(() => {
      read(user.data.id, items, offset)
    }, [items, offset])

    return (
      <Scrollbar ref={ref} onScrollFrame={onScrollFrame}>
        <div className="py-12 px-4 flex flex-col h-full">
          {!items.length ? (
            <div className="text-center text-lightest">
              There is no messages in that conversation yet
            </div>
          ) : (
            <div className="mt-auto">
              {items.map((message, i) => (
                <Message
                  key={i}
                  id={message.id}
                  isSelf={message.sender.id === user.data.id}
                  isDeleted={message.is_deleted}
                  optionReference={message.option_reference}
                  offerReference={message.offer_reference}
                  reply={message.reply}
                  text={message.text}
                  attachment={message.attachment_url}
                  date={message.created_at}
                  onRemove={() => onRemove(message.id)}
                  onReply={() => onReply(message)}
                />
              ))}
            </div>
          )}
        </div>
      </Scrollbar>
    )
  }
)

const Message = ({
  isSelf,
  isDeleted,
  reply,
  optionReference,
  offerReference,
  text,
  attachment,
  date,
  onRemove,
  onReply,
}) => {
  const dateClassName = "font-rubik text-xs text-lightest flex-shrink-0"
  const formattedDate = `${moment(date).fromNow(dateClassName)} ago`
  const modal = useContext(modalContext)

  return (
    <div className="flex mb-3">
      <div className={cx("flex items-center", isSelf && "ml-auto")}>
        {isSelf && (
          <span className={cx(dateClassName, "mr-6")}>{formattedDate}</span>
        )}
        {isDeleted ? (
          <div className="shadow text-darkest text-sm bg-light-blue px-6 py-3 my-3 rounded-full ">
            Deleted message
          </div>
        ) : (
          <ContextMenu
            items={[
              {
                title: "Reply",
                onClick: onReply,
              },
              {
                title: "Delete",
                onClick: () =>
                  modal.open(
                    <ConfirmBox
                      title="Delete this message?"
                      confirmTitle="Delete"
                      cancelTitle="Cancel"
                      onCancel={modal.close}
                      onConfirmSuccess={modal.close}
                      onConfirm={onRemove}
                    />
                  ),
              },
            ]}
          >
            {text ? (
              <div className="text-darkest bg-white p-6 rounded whitespace-pre-line shadow break-words max-w-sm">
                {optionReference ? (
                  <OptionReference offset="6" {...optionReference} />
                ) : offerReference ? (
                  <OfferReference offset="6" {...offerReference} />
                ) : (
                  reply && <Reply offset="6" {...reply} />
                )}
                {text}
              </div>
            ) : (
              attachment && (
                <a
                  href={attachment}
                  target="_blank"
                  className="bg-white p-2 rounded pointer shadow block"
                  style={{ width: "200px" }}
                >
                  {optionReference ? (
                    <OptionReference offset="2" {...optionReference} />
                  ) : offerReference ? (
                    <OfferReference offset="2" {...offerReference} />
                  ) : (
                    reply && <Reply offset="2" {...reply} />
                  )}
                  <img src={`${attachment}_200`} alt="" />
                </a>
              )
            )}
          </ContextMenu>
        )}
        {!isSelf && (
          <span className={cx(dateClassName, "ml-6")}>{formattedDate}</span>
        )}
      </div>
    </div>
  )
}

const Reply = ({ is_deleted, attachment_url, text, offset, sender }) => (
  <div
    className={cx(
      "pl-5 py-2 mb-2 border-l-4 border-mild-blue flex flex-col text-mild-blue",
      offset && `-ml-${offset}`
    )}
  >
    <span className="text-sm mb-2 font-semibold">{sender.name}:</span>
    {is_deleted ? (
      <span className="italic">Message deleted</span>
    ) : (
      (text && <span className="text-darkest">{text}</span>) || (
        <img src={`${attachment_url}_200`} className="w-auto h-8 rounded" />
      )
    )}
  </div>
)

const OptionReference = ({ offset, index, name, value }) => (
  <div
    className={cx(
      "pl-5 py-2 mb-2 border-l-4 border-mild-blue flex flex-col text-mild-blue",
      offset && `-ml-${offset}`
    )}
  >
    <span className="text-sm mb-2 font-semibold">{name}:</span>
    <Stack direction="row" spacing="2">
      <IndexedLabel index={index} />
      <span className="text-midgray">{value}</span>
    </Stack>
  </div>
)

const OfferReference = ({ offset, price }) => (
  <div
    className={cx(
      "pl-5 py-2 mb-2 border-l-4 border-mild-blue flex flex-col text-mild-blue",
      offset && `-ml-${offset}`
    )}
  >
    <span className="text-sm mb-2 font-semibold">Offers:</span>
    <Stack direction="row" spacing="2">
      <Label>Offer A</Label>
      <span className="text-midgray">${price}</span>
    </Stack>
  </div>
)

const Typing = ({
  reference,
  reply,
  onSubmit,
  onSubmitSuccess,
  onRemoveReply,
  onRemoveReference,
}) => {
  const attachments = hooks.useAttachments()
  const prev = usePrevProps({ reply, reference })

  useEffect(() => {
    if (reply && !R.equals(prev && prev.reply, reply)) {
      onRemoveReference()
    }
    if (reference && !R.equals(prev && prev.reference, reference)) {
      onRemoveReply()
    }
  }, [reply, reference])

  return (
    <Formik
      onSubmit={async (values, { resetForm }) => {
        await onSubmit(
          values.message,
          attachments.items.map(R.prop("id")),
          reply && reply.id,
          reference && reference.type === "option"
            ? reference.data.id
            : undefined,
          reference && reference.type === "offer"
            ? reference.data.id
            : undefined
        )
        resetForm()
        attachments.clear()
        onRemoveReply()
        onRemoveReference()
        onSubmitSuccess()
      }}
      initialValues={{ message: "" }}
    >
      {({ values, isSubmitting, submitForm }) => (
        <Form>
          <fieldset disabled={isSubmitting}>
            <div className="flex">
              <div className="w-full relative">
                {
                  // TODO: disabled state for attachments when uploading
                }
                {(!!attachments.items.length || reply || reference) && (
                  <div
                    className="absolute top-0 left-0 w-full pb-3"
                    style={{ transform: "translateY(-100%)" }}
                  >
                    <Stack spacing="3" stretchItem>
                      {!!attachments.items.length && (
                        <Attachments
                          items={attachments.items}
                          isDisabled={isSubmitting}
                          onRemove={attachments.remove}
                        />
                      )}
                      {reply && (
                        <TypingReply {...reply} onRemove={onRemoveReply} />
                      )}
                      {reference && (
                        <TypingReference
                          {...reference}
                          onRemove={onRemoveReference}
                        />
                      )}
                    </Stack>
                  </div>
                )}
                <Field
                  placeholder="Enter your message"
                  className="w-full h-full block p-6 text-darkest rounded focus:outline-none"
                  name="message"
                  component="textarea"
                  onKeyDown={e => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      submitForm()
                      e.preventDefault()
                      e.target.value = ""
                    }
                  }}
                />
              </div>
              <div className="ml-2">
                <div className="mb-2">
                  <Button
                    disabled={
                      (!values.message && !attachments.items.length) ||
                      attachments.items.filter(item => item.uploadId).length
                    }
                    icon={planeIcon}
                  />
                </div>
                <div>
                  <UploadDialog multiple onSelect={attachments.upload}>
                    {openDialog => (
                      <Button
                        type="button"
                        icon={attachmentIcon}
                        onClick={openDialog}
                      />
                    )}
                  </UploadDialog>
                </div>
              </div>
            </div>
          </fieldset>
        </Form>
      )}
    </Formik>
  )
}

const TypingReply = ({ onRemove, ...props }) => (
  <div className="bg-white rounded p-3 flex">
    <Reply {...props} />
    <button onClick={onRemove} className="ml-auto">
      <img src={crossIcon} alt="" />
    </button>
  </div>
)

const TypingReference = ({ onRemove, type, data }) => (
  <div className="bg-white rounded p-3 flex">
    {type === "offer" ? (
      <OfferReference {...data} />
    ) : (
      type === "option" && <OptionReference {...data} />
    )}
    <button onClick={onRemove} className="ml-auto">
      <img src={crossIcon} alt="" />
    </button>
  </div>
)

const Attachments = ({ items, onRemove }) => (
  <div className="bg-white rounded p-3">
    <Stack spacing="3" stretchItem>
      {items.map((item, i) => (
        <div key={i} className="text-darkest flex items-center">
          <img className="w-4 mr-3" src={attachmentIcon} alt="" />
          {item.name}
          <div className="ml-auto">
            {item.uploadId ? (
              <Spinner />
            ) : (
              <a className="cursor-pointer" onClick={() => onRemove(item.id)}>
                <img className="w-5" src={crossIcon} alt="" />
              </a>
            )}
          </div>
        </div>
      ))}
    </Stack>
  </div>
)

const Button = ({ type, disabled, icon, onClick }) => (
  <button
    onClick={onClick}
    type={type}
    disabled={disabled}
    className={cx(
      "w-16 h-16 bg-bright-blue flex items-center justify-center flex-shrink-0",
      disabled && "cursor-not-allowed"
    )}
  >
    <img className="w-6" src={icon} alt="" />
  </button>
)

const usePrevProps = props => {
  const ref = useRef()
  useEffect(() => {
    ref.current = props
  })
  return ref.current
}
