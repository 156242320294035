import React from "react"
import cx from "classnames"

export default ({ onClick, items, size = "base" }) => (
  <div
    onClick={onClick}
    className={cx("bg-white rounded shadow", sizes[size].root)}
  >
    {items.map((item, i) => (
      <div
        onClick={() => {
          item.onClick()
        }}
        key={i}
        className={cx(
          "rounded text-darkest hover:bg-light-blue flex items-center cursor-pointer whitespace-no-wrap",
          sizes[size].item
        )}
      >
        {item.icon && <img className="mr-3 w-4" src={item.icon} alt="" />}
        {item.title}
      </div>
    ))}
  </div>
)

const sizes = {
  small: {
    item: "px-2 h-8 text-sm",
    root: "p-2",
  },
  base: {
    root: "p-3",
    item: "px-4 h-12 text-base",
  },
}
