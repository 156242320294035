import React from "react"
import { Button } from "components/kit"
import googleIcon from "assets/google.svg"
import * as googleService from "services/google"

export default ({ children }) => (
  <div className="mt-12">
    <Button
      onClick={() => googleService.redirectToConsentScreen()}
      icon={googleIcon}
      theme="secondary"
      type="button"
      full
    >
      {children}
    </Button>
  </div>
)
