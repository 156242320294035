import * as reactRedux from "react-redux"
import * as orderApi from "api/order"
import * as invitationApi from "api/invitation"
import { connect } from "hoc/index"
import * as selectors from "modules/buyer/selectors"
import * as actions from "modules/buyer/actions"

export const provideSuppliers = connect(
  { suppliersIds: selectors.getSuppliersIds, orderId: selectors.getOrderId },
  (dispatch, { orderId }) => ({
    onRemoveSupplier: async supplierId => {
      await orderApi.removeSupplier(orderId, supplierId)
      dispatch(actions.supplierRemoved(supplierId))
    },
  })
)

export const provideInvitations = connect(
  { invitationsIds: selectors.getInvitationsIds },
  dispatch => ({
    onRemoveInvitation: async invitationId => {
      await invitationApi.removeEntry(invitationId)
      dispatch(actions.invitationRemoved(invitationId))
    },
  })
)

export const provideSupplier = connect(
  {
    name: selectors.getSupplierName,
    company: selectors.getSupplierCompany,
    conversationId: selectors.getSupplierConversationId,
  },
  dispatch => ({
    onNewMessage: (event, userId) => {
      const sound = new Audio("/new-message.mp3")

      if (
        event.name !== "new_message" ||
        userId === event.payload.message.sender_id
      )
        return

      sound.play()
      dispatch(actions.newUnreadMessage(event.payload.message.conversation_id))
    },
  })
)

export const provideInvitation = connect({
  email: selectors.getInvitationEmail,
  date: selectors.getInvitationDate,
})

export const provideDiscussLink = reactRedux.connect(
  (state, { conversationId }) => ({
    unreadMessages:
      conversationId &&
      selectors.getConversationUnreadMessages(state, { conversationId }),
  })
)
