export const ORDER_RENAMED = "ORDER_RENAMED"

export const PARAMETER_CREATED = "PARAMETER_CREATED"
export const PARAMETER_REMOVED = "PARAMETER_REMOVED"
export const PARAMETER_RENAMED = "PARAMETER_RENAMED"
export const PARAMETER_ACCEPTED = "PARAMETER_ACCEPTED"
export const PARAMETER_CONVERSATION_CREATED = "PARAMETER_CONVERSATION_CREATED"

export const OPTION_RENAMED = "OPTION_RENAMED"
export const OPTION_REMOVED = "OPTION_REMOVED"
export const OPTION_CREATED = "OPTION_CREATED"
export const OPTION_ACCEPTED = "OPTION_ACCEPTED"
export const OPTION_FILE_REMOVED = "OPTION_FILE_REMOVED"
export const OPTION_FILE_UPLOAD_REQUEST = "OPTION_FILE_UPLOAD_REQUEST"
export const OPTION_FILE_UPLOAD_SUCCESS = "OPTION_FILE_UPLOAD_SUCCESS"

export const SUPPLIER_REMOVED = "SUPPLIER_REMOVED"
export const INVITATION_REMOVED = "INVITATION_REMOVED"
export const INVITATION_CREATED = "INVITATION_CREATED"

export const SELECT_OFFER = "SELECT_OFFER"

export const NEW_UNREAD_MESSAGE = "NEW_UNREAD_MESSAGE"

export const UPDATE_REMOVED = "UPDATE_REMOVED"
