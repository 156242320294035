import * as yup from "yup"

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, "Name should have at least 3 characters")
    .required("Name is required"),
  company: yup.string().required("Company is required"),
})

export const initialValues = {
  name: "",
  company: "",
}
