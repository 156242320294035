import React, { useContext } from "react"
import ReactTooltip from "react-tooltip"
import cx from "classnames"
import { context as modalContext } from "context/modal"
import { Stack, ContextMenu, ConfirmBox } from "components/kit"
import { When } from "components/service"
import {
  OfferSelection,
  IndexedLabel,
  Label,
  Updates,
} from "components/common/order"
import * as hoc from "./hoc"

export default hoc.provideRoot(({ parameterId, supplierId, optionsIds }) => (
  <Stack stretchItem direction="col" spacing="3">
    {optionsIds.map(id => (
      <Response
        key={id}
        supplierId={supplierId}
        parameterId={parameterId}
        optionId={id}
      />
    ))}
  </Stack>
))

const Response = hoc.provideResponse(
  ({
    responseStatus,
    name,
    index,
    isSelected,
    suggestedBy,
    updates,
    onAcceptOption,
    onRejectOption,
    onRemoveUpdates,
  }) => {
    const modal = useContext(modalContext)

    return (
      <When
        cond={suggestedBy}
        component={ContextMenu}
        items={[
          {
            title: "Accept option",
            onClick: () =>
              modal.open(
                <ConfirmBox
                  title="Accept this option?"
                  confirmTitle="Accept"
                  cancelTitle="Cancel"
                  onCancel={modal.close}
                  onConfirmSuccess={modal.close}
                  onConfirm={onAcceptOption}
                />
              ),
          },
          {
            title: "Reject option",
            onClick: () =>
              modal.open(
                <ConfirmBox
                  title="Reject this option?"
                  confirmTitle="Reject"
                  cancelTitle="Cancel"
                  onCancel={modal.close}
                  onConfirmSuccess={modal.close}
                  onConfirm={onRejectOption}
                />
              ),
          },
        ]}
      >
        <When cond={isSelected} component={OfferSelection} top>
          <Stack
            data-tip
            data-for={responseStatus || "not-provided"}
            spacing="4"
            direction="row"
            alignItems="center"
          >
            <IndexedLabel
              forcedValue={suggestedBy && "*"}
              theme={
                isSelected
                  ? "bright"
                  : responseStatus === "accepted"
                  ? "gray"
                  : responseStatus === "rejected"
                  ? "red"
                  : responseStatus === "pending"
                  ? "orange"
                  : "blue"
              }
              index={index}
            />
            <Value
              isSelected={isSelected}
              responseStatus={responseStatus}
              optionName={name}
            />
            {updates && !!updates.length && (
              <div className="ml-3">
                <Updates onClick={() => onRemoveUpdates(updates)} />
              </div>
            )}
          </Stack>
        </When>
      </When>
    )
  }
)

const Value = ({ responseStatus, optionName, isSelected }) => {
  switch (responseStatus) {
    case "accepted":
      return (
        <>
          <span className={cx("text-darkest", isSelected && "font-bold")}>
            {optionName}
          </span>
          <ReactTooltip id="accepted">Accepted</ReactTooltip>
        </>
      )
    case "rejected":
      return (
        <>
          <span className="mr-2">{optionName}</span>
          <ReactTooltip id="rejected">Rejected</ReactTooltip>
        </>
      )
    case "pending":
      return (
        <>
          <span className="mr-2">{optionName}</span>
          <ReactTooltip id="pending">Pending</ReactTooltip>
        </>
      )
    default:
      return (
        <>
          <span className="mr-2">{optionName}</span>
          <ReactTooltip id="not-provided">Not provided</ReactTooltip>
        </>
      )
  }
}
