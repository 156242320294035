import React, { useState } from "react"
import cx from "classnames"
import { Button } from "components/kit"
import roundedCross from "assets/rounded-cross.svg"
import checkmark from "assets/checkmark.svg"

export default ({ onHide, onSubmit }) => {
  const [selection, setSelection] = useState([])
  const [isSubmitting, setSubmitting] = useState(false)

  const toggle = suggestion =>
    setSelection(selection =>
      selection.includes(suggestion)
        ? selection.filter(item => item !== suggestion)
        : [...selection, suggestion]
    )

  const submit = async () => {
    setSubmitting(true)
    await onSubmit(selection)
    setSubmitting(false)
  }

  return (
    <div className="relative bg-nectar rounded text-white max-w-md w-full p-12 mb-24 -mt-40">
      <div className="flex items-center mb-12">
        <p className="text-2xl">Quick start</p>
        <button onClick={onHide} className="focus:outline-none ml-auto">
          <img src={roundedCross} alt="" />
        </button>
      </div>
      <p className="mb-5">
        Lets add some parameters that are important to you.
      </p>
      {suggestions.map((suggestion, i) => (
        <button
          key={i}
          className={cx(
            "px-5 mb-3 rounded h-12 flex items-center border border-white w-full focus:outline-none",
            selection.includes(suggestion)
              ? "bg-white text-moist"
              : "text-white"
          )}
          type="button"
          onClick={() => toggle(suggestion)}
        >
          {suggestion}
          {selection.includes(suggestion) && (
            <img className="w-3 ml-auto" src={checkmark} alt="" />
          )}
        </button>
      ))}
      <Button
        onClick={submit}
        disabled={!selection.length}
        isSpinning={isSubmitting}
        theme="inversed"
        full
      >
        Add
      </Button>
    </div>
  )
}

const suggestions = ["Packaging", "Production time", "Warranty"]
