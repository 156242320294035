import React from "react"
import { Header, OrderName, HeaderTitle } from "components/common/order"
import * as hoc from "./hoc"

export default hoc.provideRoot(({ orderName }) => {
  return (
    <Header>
      <div className="flex items-start">
        <OrderName name={orderName} />
        <Client />
      </div>
    </Header>
  )
})

const Client = hoc.provideClient(({ name, company }) => (
  <div className="flex flex-col ml-64">
    <HeaderTitle>Client</HeaderTitle>
    <div className="flex items-center mt-3">
      <div className="text-mild-blue flex flex-col">
        <span className="font-bold">{name}</span>
        <span>{company}</span>
      </div>
    </div>
  </div>
))
