import React, { useState, useCallback, useContext, useEffect } from "react"
import {
  Column,
  Cell,
  CellWrap,
  CellBox,
  CellHead,
  Chat,
  QuickReference,
  DiscussLink,
  ParameterCreation,
  OptionCreation,
  Updates,
} from "components/common/order"
import { context as conversationsOnlineContext } from "context/conversations-online"
import { context as modalContext } from "context/modal"
import { context as userContext } from "context/user"
import * as parameterApi from "api/parameter"
import { IconLink, HoverList, ConfirmBox, Stack } from "components/kit"
import bin from "assets/bin.svg"
import OptionList from "./OptionList"
import ResponseList from "./ResponseList"
import * as hoc from "./hoc"

export default hoc.provideRoot(
  ({ role, parametersIds, isFormMode, onSuggestParameter }) => (
    <div className={isFormMode && "overflow-hidden"}>
      {parametersIds.map((id, i) => (
        <Parameter key={id} prio={parametersIds.length - i} parameterId={id} />
      ))}
      {role === "supplier" && (
        <ParameterCreation
          title="Suggest parameter"
          onCreate={onSuggestParameter}
        />
      )}
    </div>
  )
)

const Parameter = hoc.provideParameter(
  ({
    role,
    parameterIndex,
    conversationId,
    parameterId,
    buyerId,
    name,
    prio,
    suggestedBy,
    updates,
    onConversationCreated,
    onNewMessage,
    onRemoveParameter,
    onRenameParameter,
    onRemoveUpdates,
  }) => {
    const [reference, setReference] = useState()
    const user = useContext(userContext)
    const modal = useContext(modalContext)
    const createConversation = useCallback(async () => {
      const conversationId = (await parameterApi.createConversation(
        parameterId,
        buyerId
      )).conversation_id

      onConversationCreated(parameterId, conversationId)

      return conversationId
    }, [parameterId])

    const conversationsUpdate = useContext(conversationsOnlineContext)

    useEffect(() => {
      if (conversationId) {
        conversationsUpdate.connect(conversationId)
        conversationsUpdate.subscribe(conversationId, event =>
          onNewMessage(event, user.data.id)
        )
      }
    }, [conversationId])

    return (
      <div className="flex">
        <Column>
          <Cell
            isEditable={!!suggestedBy}
            contextMenu={
              !!suggestedBy && [
                {
                  title: "Delete parameter",
                  icon: bin,
                  onClick: () =>
                    modal.open(
                      <ConfirmBox
                        title="Delete this parameter?"
                        confirmTitle="Delete"
                        cancelTitle="Cancel"
                        onCancel={modal.close}
                        onConfirmSuccess={modal.close}
                        onConfirm={() => onRemoveParameter(parameterId)}
                      />
                    ),
                },
              ]
            }
            border
            title={name}
            postfix={
              <Stack direction="row" alignItems="center" spacing="2">
                {!!suggestedBy && "(suggested)"}
                {updates && !!updates.length && (
                  <Updates onClick={() => onRemoveUpdates(updates)} />
                )}
              </Stack>
            }
            onSaveBlur={name => onRenameParameter(parameterId, name)}
          >
            <OptionList isSuggested={!!suggestedBy} parameterId={parameterId} />
          </Cell>
        </Column>
        {role === "supplier" && (
          <Column width="35vw">
            <Chat
              sidebar={<Reference onSelect={setReference} />}
              reference={reference}
              conversationId={conversationId}
              createConversation={createConversation}
              onRemoveReference={() => setReference(void 0)}
              onClose={() => setReference(void 0)}
            >
              {({ open }) => (
                <CellWrap>
                  <ResponseLine
                    displayChat={!suggestedBy}
                    conversationId={conversationId}
                    onChatOpen={open}
                  />
                  <CellBox>
                    <ResponseList parameterId={parameterId} prio={prio} />
                    <ConnectedOptionCreation parameterId={parameterId} />
                  </CellBox>
                </CellWrap>
              )}
            </Chat>
          </Column>
        )}
      </div>
    )
  }
)

const ResponseLine = hoc.provideResponseLine(
  ({ unreadMessages, onChatOpen, displayChat, contextMenu }) => (
    <CellHead
      contextMenu={contextMenu}
      leftLink={
        displayChat && (
          <DiscussLink unreadMessages={unreadMessages} onClick={onChatOpen} />
        )
      }
    />
  )
)

const Reference = hoc.provideQuickReference(QuickReference)

const ConnectedOptionCreation = hoc.provideOptionCreation(OptionCreation)
