import React, { useContext } from "react"
import { parse } from "query-string"
import { Formik, Form } from "formik"
import { context as userContext } from "context/user"
import { Field } from "components/service"
import {
  Layout,
  Row,
  GoogleAuth,
  Box,
  BottomLink,
} from "components/common/user"
import { Button, Input } from "components/kit"
import * as userApi from "api/user"
import * as paths from "paths.js"
import * as data from "./data"

export default () => {
  const user = useContext(userContext)

  async function submitForm(formData, { setFieldError, setSubmitting }) {
    try {
      const { redirect_to } = parse(document.location.search)

      user.setParameters({ forceRedirect: redirect_to })
      user.createSession(await userApi.localSignIn(formData))
    } catch (err) {
      if (err.response && err.response.data.type === "invalid_login") {
        setFieldError(
          "password",
          "Incorrect password. If you need to reset your password, please contact us."
        )
        setSubmitting(false)
        return
      }
      throw err
    }
  }

  return (
    <Layout title="Sign in">
      <Box>
        <Formik
          validationSchema={data.validationSchema}
          initialValues={data.initialValues}
          onSubmit={submitForm}
        >
          {({ isSubmitting }) => (
            <Form>
              <Row>
                <Field
                  type="text"
                  name="email"
                  placeholder="Email"
                  component={Input}
                />
              </Row>
              <Row>
                <Field
                  type="password"
                  name="password"
                  placeholder="Password"
                  component={Input}
                />
              </Row>

              <Button full disabled={isSubmitting} type="submit">
                Sign In
              </Button>
              <GoogleAuth>Sign In with Google</GoogleAuth>
            </Form>
          )}
        </Formik>
        <BottomLink to={paths.signUp}>Create an account</BottomLink>
      </Box>
    </Layout>
  )
}
