import React, { useState } from "react"
import cx from "classnames"

export default ({ items }) => {
  const [active, setActive] = useState(0)

  return (
    <>
      <div className="flex border-b border-paper">
        {items.map((item, i) => (
          <button
            key={i}
            onClick={() => setActive(i)}
            className={cx(
              "pr-6 mr-6 py-4 -mt-4 block border-b-2 border-transparent -mb-px text-moist focus:outline-none",
              i === active && "border-moist font-bold",
              i !== active && "hover:text-moist"
            )}
          >
            {item.title}
          </button>
        ))}
      </div>
      <div className="mt-12">{items.find((item, i) => i === active).body}</div>
    </>
  )
}
