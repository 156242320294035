import React from "react"

export default ({ children, onClick }) => (
  <button
    onClick={onClick}
    className="text-darkest focus:outline-none py-1 -my-1 px-2 -mx-2 rounded hover:bg-light-blue"
  >
    {children}
  </button>
)
