import "./style.css"

import React from "react"
import ReactDOM from "react-dom"
import { Router } from "@reach/router"
import { Page } from "./components/service"
import Context from "./context"
import * as pages from "./pages"
import * as paths from "./paths"
import * as guards from "./guards"

ReactDOM.render(
  <Context>
    <Router>
      <Page
        path={paths.signIn}
        guard={guards.unauthenticated}
        component={pages.SignIn}
      />
      <Page
        path={paths.signUp}
        guard={guards.unauthenticated}
        component={pages.SignUp}
      />
      <Page
        path={paths.googleCallback}
        guard={guards.unauthenticated}
        component={pages.GoogleCallback}
      />
      <Page
        path={paths.profileSetup}
        guard={guards.profileNotAdded}
        component={pages.ProfileSetup}
      />
      <Page
        path={paths.orders}
        guard={guards.profileAdded}
        component={pages.Orders}
      />
      <Page
        path={paths.order}
        guard={guards.profileAdded}
        component={pages.Order}
      />
    </Router>
  </Context>,
  document.getElementById("root")
)

// 1. Split selectors by dir
// 1. Splitting code inside of selectors
// - refactor selectors
// - revisit state shape
// 2. Install redux-saga

// Problems:
// 1. Selectors
// 2. Relation of option -> response for supplier

// 1. Read about hooks in depth to eleminate extra renders in the app
// 2. Performance optimizations:
// - # Eliminate multiple table renders when updating the option
// - When option updated, the whole page (including SmartList's) gets updated as well
// - ? memoization in selectors to avoid cost computations on every render, as these renders may not affect computed data
//
// Memoization in selectors, memoization in components, useCallback hook, state in context
// measure performance before / after optimizations

// REVIEW CODE, pay attention to reusability of lists(common/order)

// --- Read full react tutorial before approaching redux, undertand why redux is considered as legacy
// - Clarify response many-to-many handling
// - Refactor selectors to not repeat the code
// 5. TODO: how to solve issue with passing array to ContextMenu and having id from the component?
//
//
//
// TODO: Avoid nesting in file structure? (the similar way, nesting could be avoided in REST json response)
//
//
// Refactoring
// - Single schema for the order
// - Remove components nesting. Have component files instead of dirs
// - Selectors of the buyer
// - Introduce conception of modules? (Context + UI), like chat
//
//
//
// 1. Do one thing and do it great
// 2. No nesting (of the same type, component-in-component, hooks-in-hooks etc)
