import React from "react"
import cx from "classnames"

export default ({ title = "Discuss", unreadMessages, onClick }) => (
  <button onClick={onClick} type="button" className="flex items-center">
    <Icon color={!unreadMessages ? "#B1BDD6" : "#5680F8"} />
    <span
      className={cx(
        "ml-2 uppercase text-xs font-rubik font-medium",
        unreadMessages ? "text-bright-blue" : "text-lightest"
      )}
    >
      {unreadMessages ? `(${unreadMessages})` : title}
    </span>
  </button>
)

const Icon = ({ color }) => (
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none">
    <g clipPath="url(#clip0)">
      <path
        d="M0.125977 19.044C0.125977 20.022 0.736075 20.294 1.48446 19.644L6.2656 15.516H14.2111C15.3707 15.516 16.4829 15.063 17.3028 14.2566C18.1228 13.4502 18.5835 12.3564 18.5835 11.216V4.3C18.5835 3.15957 18.1228 2.06585 17.3028 1.25944C16.4829 0.453034 15.3707 0 14.2111 0L4.49835 0C3.33872 0 2.22659 0.453034 1.40661 1.25944C0.586636 2.06585 0.125977 3.15957 0.125977 4.3V19.044Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="18.7097" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
